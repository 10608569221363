<template>
  <div class="d-flex justify-center align-center flex-column">
    <property-form
      :value="
        value ||
        Object.keys(fields).reduce((a, v) => {
          a[v] = fields[v].default ?? '';
          return a;
        }, {})
      "
      :fields="fields"
      @input="doSave"
    />
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import MultiSelectCheckbox from "./MultiSelectCheckbox.vue";
import PropertyForm from "@/components/PropertyForm";
import ErrorMessageWrapper from "./ErrorMessageWrapper.vue";
import { mapActions, mapGetters } from "vuex";
import { VSelect } from "vuetify/lib/components";
import { ROLE_TRANSLATIONS } from "@/utils/constants";

export default {
  name: "UserForm",
  components: { PropertyForm, ErrorMessageWrapper },
  data() {
    return {
      phoneRules: [
        (v) =>
          !v ||
          /^\+\d{1} \(\d{3}\) \d{3} \d{2} \d{2}$/.test(v) ||
          "Пример: +7 (999) 111 22 33",
      ],
      roleRules: [(v) => !!v || "Обязательное поле"],
      companyRules: [(v) => !!v || "Обязательное поле"],
      passwordRules: [
        (v) => !!v || "Обязательное поле",
        (v) =>
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/.test(v) ||
          "Минимум 8 символов, цифры, буквы, знаки",
        (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      ],
      emailRules: [
        (v) => (!!v && !v.includes(" ")) || "Обязательное поле без пробелов",
        (v) => /.+@.+\..+/.test(v) || "Пример: abc@de.fg",
      ],
      lastNameRules: [(v) => !!v || "Обязательное поле"],
      firstNameRules: [(v) => !!v || "Обязательное поле"],
    };
  },

  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("modals", ["modalFormComponent"]),
    ...mapGetters("companies", ["companies"]),

    value() {
      let result = this.modalFormComponent?.value;
      if (!result) return result;
      result.balance_vision = !result.feature_bans?.includes("BALANCE_VISION");
      result.patient_vision = !result.feature_bans?.includes("ONLY_OWNED");
      delete result.feature_bans;
      return result;
    },
    roleOptions() {
      // Transform role_hash into an array suitable for dropdown
      return Object.entries(ROLE_TRANSLATIONS).map(([value, text]) => ({
        value, // Convert the key to an integer
        text,
      }));
    },
    fields() {
      let company_id_field = {};
      if (
        this.modalFormComponent?.value === undefined ||
        this.value.role !== "manager"
      )
        company_id_field = {
          label: "Компания",
          default: "",
          visible: this.user?.role === "admin",
          component: VSelect,
          items: this.companies.map((c) => ({ value: c.id, text: c.name })),
          rules: this.companyRules,
        };
      else
        company_id_field = {
          label: "Компания",
          default: "",
          twoColumns: true,
          visible: this.user?.role === "admin",
          component: MultiSelectCheckbox, // условие на admin'a и то что редактируем и убрать из создания
          items: this.companies.map((c) => ({
            value: c.id,
            text: c.name,
            selected: this.value.id === c.manager_id,
          })),
          rules: this.companyRules,
        };

      return {
        email: {
          label: "E-Mail (login)",
          visible: true,
          default: "",
          rules: this.emailRules,
        },
        first_name: {
          label: "Имя",
          visible: true,
          default: "",
          rules: this.firstNameRules,
        },
        last_name: {
          label: "Фамилия",
          visible: true,
          default: "",
          rules: this.lastNameRules,
        },
        position: {
          label: "Должность",
          visible: true,
          default: "",
        },
        patient_vision: {
          label: "Доступ к пациентам",
          type: "boolean",
          default: null,
          visible:
            this.user?.role === "admin" || this.user?.role === "company-owner",
          role_visibility:
            this.user?.role === "admin" ? ["company-user"] : undefined,
        },
        balance_vision: {
          label: "Доступ к балансу",
          type: "boolean",
          default: null,
          visible:
            this.user?.role === "admin" || this.user?.role === "company-owner",
          role_visibility:
            this.user?.role === "admin" ? ["company-user"] : undefined,
        },
        password: {
          label: "Пароль",
          visible: true,
          default: "",
          rules: this.passwordRules,
        },
        company_id: company_id_field,
        role: {
          default: "",
          label: "Роль",
          visible: this.user?.role === "admin",
          component: VSelect,
          items: this.roleOptions,
          rules: this.roleRules,
        },
        phone: {
          default: "",
          label: "Телефон",
          visible: true,
          rules: this.phoneRules,
        },
      };
    },
  },

  beforeMount() {
    this.request_users();
    this.request_companies();
  },
  methods: {
    ...mapActions("companies", ["request_companies"]),
    ...mapActions("users", ["request_users"]),

    doSave: async function (value) {
      await this.request_users("all=1");
      // Existing user, password is undefined
      const conflictUser = this.users.find((user) => {
        return user.email == value.email;
      });
      if (conflictUser && !this.value) {
        var message = "Попробуйте выбрать другой E-Mail";
        const hint = !conflictUser.active
          ? " или разархивируйте текущий (Находится в архиве)"
          : "";

        await this.$refs.error_wrapper.showErrorMessage(
          "Данный E-Mail уже занят",
          message + hint
        );
        return;
      }
      const userId = value.id;
      const isExistingUser = userId !== undefined && userId !== null;
      const password = value.password;

      if (this.user.role === "company-owner") {
        Object.assign(value, {
          company_id: this.user.company_id,
          role: "company-user",
        });
      }

      let feature_bans = [];
      // Обработка флагов
      if (!value.patient_vision && value.role === "company-user")
        feature_bans.push("ONLY_OWNED");
      delete value.patient_vision;
      if (!value.balance_vision && value.role === "company-user")
        feature_bans.push("BALANCE_VISION");
      // Обработали флаги, загоняем в объект
      value.feature_bans = feature_bans;

      // Обработка обновления компаний, если пользователь - менеджер
      if (value.role === "manager") {
        value.manager_companies = value.company_id;
        value.company_id = null;
      }

      if (isExistingUser && password === undefined) {
        this.$emit("confirm", { ...value });
      } else {
        // New user or existing user with a defined password
        this.$emit("confirm", { ...value });
      }
    },
  },
};
</script>

<style scoped lang="scss" />

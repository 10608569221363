<template>
  <property-form :value="value" :fields="fieldDefs" @input="doSave">
  </property-form>
</template>

<script>
import PropertyForm from "@/components/PropertyForm";
import { mapGetters } from "vuex";
import { VFileInput, VSelect } from "vuetify/lib/components";
export default {
  name: "ProductForm",
  components: { PropertyForm },
  data() {
    return {
      nameNumberRules: [(v) => !!v || "Обязательное поле"],
      sessionNumberRules: [(v) => !!v || "Обязательное поле"],
      productTypeRules: [(v) => !!v || "Обязательное поле"],
      zipFileRules: [(v) => !!v || "Обязательное поле"],
    };
  },
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    value() {
      return this.modalFormComponent?.value;
    },
    fieldDefs() {
      return {
        session_number: {
          order: 1,
          label: "Номер сессии",
          component: VSelect,
          items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          visible: true,
          rules: this.sessionNumberRules,
        },
        product_type: {
          order: 2,
          label: "Тип",
          component: VSelect,
          items: ["PC", "VR", "MG"],
          visible: true,
          rules: this.productTypeRules,
        },
        file: {
          order: 3,
          label: "Видео файл",
          component: VFileInput,
          counter: true,
          type: "file",
          visible: true,
          rules: this.zipFileRules,
        },
      };
    },
  },
  methods: {
    doSave(value) {
      this.$emit("confirm", { ...value });
    },
  },
};
</script>

<style scoped></style>

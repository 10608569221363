var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Пациенты ")]),_c('v-chip-group',{attrs:{"active-class":"primary accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Активные ")],1),_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-archive")]),_vm._v(" Архивные ")],1)],1),_c('object-grid-advanced',{attrs:{"title":"Пациенты","value":_vm.patients_with_responsible_info,"field-defs":[
      { text: 'Пациент', value: 'fio', width: '45%' },
      { text: 'Ответственный', value: 'user_full_name', width: '43%' },
    ],"hide-toolbar":false,"show-actions":{
      edit: !_vm.selection,
      delete: !_vm.selection,
      restore: _vm.selection,
    },"instanceId":`patientsSearch_${_vm.selection ? 'archive' : 'active'}`},on:{"itemSelected":(v) => (this.selected = v),"doEditItem":(item) =>
        _vm.$router.push({
          name: 'admin-patient',
          params: {
            patient_id: String(item.id),
          },
        }),"doDeleteItem":_vm.deletePatient,"doRestoreItem":_vm.restorePatient}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app app>
    <v-navigation-drawer
      v-model="show_drawer"
      :permanent="!$vuetify.breakpoint.xsOnly"
      style="box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.1)"
      app
    >
      <div class="d-flex align-center">
        <img
          src="/logo.svg"
          width="60"
          height="60"
          style="margin-right: 10px"
        />
        ЛИЧНЫЙ КАБИНЕТ
      </div>
      <v-list class="pa-0">
        <v-list-item-group active-class="active-item">
          <ns-menu-item
            v-for="item in filteredMenu"
            :key="item.label"
            :icon="item.icon"
            :to="{ name: item.path }"
            :label="item.label"
            :active="$route.path.indexOf(item.keyword) !== -1"
          />
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="background-color: #f0f8f6" class="mb-6">
      <v-container fluid class="ma-0 pa-0">
        <v-toolbar height="64x" flat style="z-index: 1">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.xsOnly"
            @click.stop="show_drawer = !show_drawer"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
              v-if="!user.feature_bans.includes('BALANCE_VISION')"
            >
              Баланс: {{ userCompany?.custom_data?.balance || 0 }}
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
              v-if="userCompany?.manager_id"
            >
              Менеджер: {{ this.companyManager }}
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
            >
              {{ userCompany?.name || 'ООО "Новый Мир"' }}
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-toolbar-items class="d-flex">
            <v-expansion-panels hover flat accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0" style="min-height: 100%">
                  <div class="d-flex justify-center align-center pa-0">
                    <v-icon color="primary">mdi-account-circle</v-icon>
                    <span
                      class="user-email text-truncate"
                      style="min-width: 40px"
                      >{{ user.email }}</span
                    >
                  </div>
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="expansion-panel-content-1">
                  <div class="d-flex justify-center align-center">
                    <v-btn
                      depressed
                      color="white"
                      @click="doLogout"
                      class="pa-0 flex-grow-1"
                    >
                      <div>
                        <v-icon color="primary">mdi-exit-run</v-icon>
                        Выход
                      </div>
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-toolbar-items>
        </v-toolbar>
      </v-container>
      <v-container class="px-2 px-sm-10" fluid app>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NsMenuItem from "@/components/NsMenuItem";

export default {
  name: "CustomerLayout",
  components: { NsMenuItem },
  data() {
    return {
      companyManager: "",
      show_drawer: false,
      menu: [
        {
          path: "company-products",
          label: "Продукты",
          icon: "mdi-apps",
          keyword: "products",
        },
        {
          path: "company-users",
          label: "Сотрудники",
          icon: "mdi-account-circle",
          keyword: "users",
        },
        {
          path: "company-patients",
          label: "Пациенты",
          icon: "mdi-account-plus",
          keyword: "patients",
        },
        {
          path: "company-reports",
          label: "Отчеты",
          icon: "mdi-chart-arc",
          keyword: "reports",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("companies", ["companies"]),

    filteredMenu() {
      // Условие для фильтрации меню
      if (this.user.role === "company-user") {
        return this.menu.filter((item) => item.keyword !== "reports");
      }
      return this.menu;
    },

    userCompany() {
      return this.companies.find(
        (company) => company.id === this.user.company_id
      );
    },
  },
  methods: {
    ...mapActions(["doLogout"]),
    ...mapActions("companies", ["request_companies"]),
  },
  async mounted() {
    await this.request_companies();
    if (this.userCompany.Manager)
      this.companyManager =
        this.userCompany.Manager.first_name +
        " " +
        this.userCompany.Manager.last_name;
  },
};
</script>

<style scoped lang="scss">
.toolbar-content {
  background-color: #f0f8f6;
  color: #d86568 !important;
}
.user-email {
  max-width: 200px;
}

#expansion-panel-content-1::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .user-email {
    max-width: 50px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"overflow-hidden rounded-xl",attrs:{"color":"#f0f8f6"}},[_c('div',{staticClass:"text-right ma-3"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"pb-5",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center text-h4",attrs:{"cols":"16"}},[_c('div',[_vm._v("Назначить или отменить")]),_c('div',[_vm._v("просмотр продукта")])])],1),_c('v-card-text',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"0","id":"tree"}},[_c('v-treeview',{attrs:{"items":_vm.categories},on:{"update:open":_vm.renderTreeNode},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [(item.kinescope_id)?_c('div',[_c('v-list',_vm._l(([item].filter((i) => i.kinescope_id)),function(p){return _c('v-list-group',{key:p.id,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2",staticStyle:{"max-width":"500px"},domProps:{"textContent":_vm._s(`Сессия №${p.session_number}`)}})],1)]},proxy:true}],null,true)},[_c('v-list-item',{key:p.id + item.kinescope_id},[_c('v-simple-checkbox',{attrs:{"value":_vm.selected_products.includes(p),"color":"primary"},on:{"input":function($event){_vm.selected_products.includes(p)
                        ? _vm.doUnselectSession(p)
                        : _vm.doSelectSession(p)}}}),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(p.session_number)+" ")])],1)],1)}),1)],1):_c('div',{style:(item.children.length === 0
                ? { marginLeft: '30px' }
                : { display: 'flex', gap: '10px' })},[_vm._v(" "+_vm._s(item.name)+" "),(
                ['PC', 'VR', 'MG'].includes(item.name) &&
                item.children.length !== 0
              )?_c('v-simple-checkbox',{attrs:{"value":!item.children.some(
                  (product) => !_vm.selected_products.includes(product)
                )},on:{"input":function($event){!item.children.some(
                  (product) => !_vm.selected_products.includes(product)
                )
                  ? _vm.unselectAll(item.children)
                  : _vm.selectAll(item.children)}}}):_vm._e()],1)]}}])})],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#d86568","width":"150px","height":"50px","border-radius":"50px"},attrs:{"disabled":_vm.selected_products.length === 0},on:{"click":function($event){return _vm.$emit('confirm', [_vm.selected_products])}}},[_vm._v(" Назначить ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
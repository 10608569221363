export default class LastResultCallableChain {
  prevCall = {};
  currentCall = {};

  call(prepareParams, action, commit) {
    this.prevCall = this.currentCall;
    this.prevCall.canCommit = false;

    this.currentCall = {};
    this.currentCall.params = prepareParams(this.prevCall.params || {});
    this.currentCall.canCommit = true;

    return ((currentCall) => {
      return action(currentCall.params).then((r) => {
        if (currentCall.canCommit) {
          return commit(r, currentCall.params);
        }
      });
    })(this.currentCall);
  }
}

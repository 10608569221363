var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Личная информация ")]),_c('v-card-text',[_c('property-form',{attrs:{"value":_vm.user,"fields":[
            'email',
            'first_name',
            'last_name',
            'referral_code',
            'phone',
            'inn',
            'ogrn',
          ].reduce((a, v) => {
            a[v] = { visible: true };
            return a;
          }, {})},on:{"input":_vm.doUpdateUser}})],1)],1),_c('object-grid-advanced',{staticClass:"mt-5",attrs:{"value":_vm.categories,"field-defs":[
      { text: 'Категория', value: 'name', width: '40%' },
      {
        text: 'Стоимость назначения',
        value: 'session_price',
        width: '38%',
      },
    ],"show-actions":{
      edit: true,
    },"instanceId":`preferenceSearch_${_vm.selection ? 'archive' : 'active'}`},on:{"doEditItem":_vm.editCategoryPrice}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <v-card>
      <v-text-field
        v-model="searchQuery"
        flat
        solo
        style="border: 1px solid #23919e; border-radius: 6px"
        append-icon="mdi-magnify"
        label="Найти"
        single-line
        hide-details
      ></v-text-field>

      <v-list v-if="searchQuery.length > 1">
        <v-list-item v-for="item in filteredItems" :key="item.value">
          <v-list-item-action>
            <v-checkbox
              v-model="item.selected"
              @change="companyChange(item)"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-title class="text-h6 blue-grey lighten-4 text-center mb-3">
        Выбранные компании
      </v-card-title>
      <div v-if="selectedCompanies.length === 0" class="text-center">
        {{ "Ничего не выбрано" }}
      </div>

      <v-list>
        <v-list-item v-for="item in selectedCompanies" :key="item.value">
          <v-list-item-action>
            <v-checkbox
              v-model="item.selected"
              @change="companyChange(item)"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MultiSelectCheckbox",
  props: {
    items: Array,
  },
  data() {
    return {
      companies: this.items,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    filteredItems() {
      return this.companies.filter((item) =>
        item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedCompanies() {
      return this.companies.filter((item) => item.selected === true);
    },
  },
  methods: {
    selectedCompaniesChange() {
      this.$emit("input", this.selectedCompanies);
    },
    companyChange(item) {
      //let company = this.items.find((company) => company.value === item.value);
      this.$set(item, "selected", item.selected);
      this.selectedCompaniesChange();
    },
  },
  mounted() {
    this.selectedCompaniesChange();
  },
};
</script>

<template>
  <v-container class="login-container">
    <v-layout wrap>
      <v-flex>
        <v-card class="flex-with-image" elevation="4" tag="section">
          <v-card-title>
            <v-flex class="d-flex justify-center align-center">
              <span
                class="my-sm-8 text-center white--text headline text-h4 text-break"
              >
                Вход в Личный кабинет</span
              >
            </v-flex>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-form class="d-flex flex-column align-center">
              <v-text-field
                flat
                solo
                class="text-field"
                outline
                hide-details
                clearable
                label="E-mail"
                type="text"
                v-model="username"
                autocomplete="username"
                @keyup.enter="login"
              />
              <v-text-field
                flat
                solo
                class="text-field mt-4"
                outline
                hide-details
                clearable
                label="Пароль"
                type="password"
                v-model="password"
                autocomplete="password"
                @keyup.enter="login"
              />
              <div style="color: #d86568; font-weight: bold">
                <div v-if="errorMessage == ''"><br /></div>
                {{ errorMessage }}
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex flex-column align-center">
            <v-btn
              class="mb-5 text-decoration-underline white--text"
              text
              @click="$emit('goRecover')"
            >
              Забыли пароль?
            </v-btn>
            <v-btn
              class="white--text ma-0"
              style="
                background-color: #d86568;
                width: 90%;
                height: 65px;
                border-radius: 50px;
              "
              @click="login"
            >
              <v-icon left>mdi-lock</v-icon>
              Вход
            </v-btn>
            <div
              style="
                text-align: center;
                width: 90%;
                color: rgba(255, 255, 255, 0.5);
                margin-top: 20px;
              "
            >
              Используя сервис, вы соглашаетесь на обработку персональных данных
              и принимаете условия политики.
            </div>
          </v-card-actions>
          <v-layout align-center justify-center>
            <div class="bottom-text">
              <p class="caption my-3">
                <a class="bottom-text" href="#">Privacy Policy</a>
                |
                <a class="bottom-text" href="#">Terms of Service</a>
              </p>
            </div>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginForm",
  emits: ["goRecover"],
  data() {
    return {
      password: null,
      username: null,
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions(["doLogin"]),
    check_username(username) {
      return username.trim();
    },
    async login() {
      try {
        this.username = this.check_username(this.username);
        await this.doLogin({ email: this.username, password: this.password });
        // this push forces router to render home component instead of previous
        // maximum value may be achieved if we (TODO:) force vue-app to unrender the previous component
        // now we're just skipping it, after user login-in;
        this.$router.push({ name: "home" });
        this.errorMessage = "";
      } catch (error) {
        this.errorMessage = "Неправильный логин или пароль";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flex-with-image {
  border-radius: 30px !important;
  width: 600px;
  height: 550px;
  background-image: url("@/assets/login_page_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #23919e !important;
}
.text-field {
  height: 60px;
  width: 90%;
  border-radius: 6px;
  background-color: white;
  align-items: center;
}
.bottom-text {
  color: rgba(255, 255, 255, 0.5);
}
.login-container {
  width: 600px;
  height: 550px;
}

@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .login-container {
    width: 100%;
    height: max-content;
  }
}
</style>

<style lang="scss">
#inspire {
  .v-application--wrap {
    justify-content: center;
  }
}
</style>

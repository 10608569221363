var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Сотрудники "),(_vm.user.role === 'company-owner')?_c('v-btn',{staticClass:"pa-5 px-sm-10 white--text",attrs:{"color":"#d86568","large":"","rounded":""},on:{"click":_vm.addNewUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1):_vm._e()],1),(this.user.role === 'company-owner')?_c('v-chip-group',{attrs:{"active-class":"primary accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Активные ")],1),_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-archive")]),_vm._v(" Архивные ")],1)],1):_vm._e(),_c('object-grid-advanced',{attrs:{"value":_vm.users.filter(
        (user) => user.role !== 'company-owner' && user.role !== 'patient'
      ),"title":"Сотрудники","field-defs":[
      {
        text: 'Сотрудник',
        value: 'full_name',
        width: '33%',
      },
      { text: 'E-mail', value: 'email', width: '33%' },
      {
        text: 'Количество пациентов',
        value: 'patients_count',
        width: '22%',
      },
    ],"show-actions":{
      edit: _vm.user.role === 'company-owner' && !_vm.selection,
      delete: _vm.user.role === 'company-owner' && !_vm.selection,
      restore: _vm.user.role === 'company-owner' && _vm.selection,
    }},on:{"doEditItem":_vm.editUser,"doDeleteItem":_vm.deleteItem,"doRestoreItem":_vm.restoreItem}}),_c('error-message-wrapper',{ref:"error_wrapper"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
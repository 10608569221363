<template>
  <v-container>
    <v-btn v-if="session_id && notProd" @click="resetViewProgress"
      >Сбросить прогресс просмотра</v-btn
    >
    <kinescope-player
      ref="kinescope"
      :video-id="`${video_data}?t=${this.startTime}`"
      height="300vh"
      @play="handlePlay"
      @pause="handlePause"
      @ended="handleEnded"
      @ready="handleReady"
      @time-update="handleTimeUpdate"
    ></kinescope-player>
  </v-container>
</template>

<script>
import { KinescopePlayer } from "@kinescope/vue-kinescope-player";

export default {
  name: "NSPlayer",
  props: ["video_data", "session_id", "view_progress"],
  components: { KinescopePlayer },
  emits: ["videoEnded", "updateViewProgress"],
  data() {
    return {
      playing: false,
      saveProgressLocalInterval: undefined,
      saveProgressToDBInterval: undefined,
      notProd: !process.env.VUE_APP_PRODUCT_DATA_PATH.includes("account"),
      currentTime: 0,
      startTime: 0,
      isTimeChangedManually: false,
      lastRecordedTime: 0,
    };
  },
  beforeMount() {
    // Интервальное сохранение прогресса воспроизведения в локальном хранилище
    this.saveProgressLocalInterval = setInterval(() => {
      if (this.playing) {
        this.saveProgressLocal();
      }
    }, 1000);

    // Интервальное сохранение прогресса воспроизведения в БД
    this.saveProgressToDBInterval = setInterval(() => {
      if (this.playing) {
        this.saveProgressToDB();
      }
    }, 5000);

    // Восстановление прогресса воспроизведения видео
    if (this.session_id) {
      const currentTimeFromLocalStorage = Number(
        localStorage.getItem(this.session_id.toString()) ?? 0
      );
      const currentTimeFromDatabase = this.view_progress ?? 0;
      console.log("currentTimeFromLocalStorage", currentTimeFromLocalStorage);
      console.log("currentTimeFromDatabase", currentTimeFromDatabase);
      let currentTime =
        currentTimeFromLocalStorage > currentTimeFromDatabase
          ? currentTimeFromLocalStorage ?? 0
          : currentTimeFromDatabase ?? 0;
      console.log("currentTime", currentTime);
      this.currentTime = currentTime;
      this.startTime = Math.round(currentTime);
    }
  },
  methods: {
    handleReady() {
      console.log("ready");

      // Восстановление прогресса воспроизведения видео
      this.lastRecordedTime = this.currentTime;

      console.log("startTime", this.startTime);
      this.$refs.kinescope.player.seekTo(this.startTime);
    },
    handlePlay() {
      console.log("play");

      this.playing = true;
    },
    handlePause() {
      // Сохранение прогресса воспроизведения
      this.$refs.kinescope.player
        ?.getCurrentTime()
        .then((currentTime) => (this.currentTime = currentTime));

      this.playing = false;

      this.saveProgressLocal();
      this.saveProgressToDB();
      console.log("pause");
    },
    handleEnded() {
      this.played = false;
      this.saveProgressLocal();
      this.saveProgressToDB();
      this.$emit("videoEnded");
    },
    saveProgressLocal() {
      // только если передано session_id
      if (this.session_id) {
        this.$refs.kinescope.player.getCurrentTime().then((currentTime) => {
          localStorage.setItem(this.session_id, currentTime);
        });
      }
    },
    saveProgressToDB() {
      // только если передано session_id
      if (this.session_id) {
        this.$refs.kinescope.player.getCurrentTime().then((currentTime) => {
          this.$emit("updateViewProgress", currentTime);
        });
      }
    },
    resetViewProgress() {
      localStorage.removeItem(this.session_id);
      this.$emit("updateViewProgress", 0);
      this.$refs.kinescope.player.seekTo(0);
    },
    closeNSPlayer() {
      this.$refs.kinescope.player?.pause();

      // Сброс прогресса воспроизведения
      this.currentTime = 0;
      this.lastRecordedTime = 0;
      console.log("closeNSPlayer");
    },
    handleTimeUpdate(timeData) {
      // Пропустить выполнение если
      // время было изменено внутри кода.
      // Нужно чтобы избежать зацикливания
      if (this.isTimeChangedManually) {
        this.isTimeChangedManually = false;
        return;
      }

      const delta = Math.abs(timeData.currentTime - this.lastRecordedTime);

      // Если разница между текущим временем
      // и последним сохраненным больше 1 (значение определено тестами)
      // то считаем что время было изменено пользователем
      // восстанавливаем исходное время воспроизведения
      if (delta > 1) {
        this.isTimeChangedManually = true;
        this.$refs.kinescope.player.seekTo(this.lastRecordedTime);
      } else {
        this.lastRecordedTime = timeData.currentTime;
      }
    },
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    clearInterval(this.saveProgressLocalInterval);
    clearInterval(this.saveProgressToDBInterval);
    this.closeNSPlayer();
  },
};
</script>

<style scoped lang="scss"></style>

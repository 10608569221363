import pluralize from "pluralize";
import LastResultCallableChain from "../api/LastResultCallableChain";

export default class CRUDModel {
  constructor(service, options = {}) {
    const modelNamePluralize = pluralize(service._modelName);
    this._request_all_chain = new LastResultCallableChain();
    this._vuexModule.state[modelNamePluralize] = [];
    this._vuexModule.getters[`${modelNamePluralize}`] = (state) =>
      state[modelNamePluralize];

    if (options.sorting) {
      this._vuexModule.getters[`sorted_${modelNamePluralize}`] = (state) =>
        state[modelNamePluralize].slice().sort(options.sorting);
    }

    this._vuexModule.mutations[`set_${modelNamePluralize}`] = (state, value) =>
      (state[modelNamePluralize] = value);
    this._vuexModule.actions[`request_${modelNamePluralize}`] = (
      ctx,
      payload
    ) => {
      if (
        options.onlyOnce &&
        ctx.state[modelNamePluralize] &&
        ctx.state[modelNamePluralize].length > 0
      ) {
        return Promise.resolve();
      }
      if (options.refresh_all) {
        return this._request_all_chain.call(
          (params) => Object.assign(params, payload || {}),
          () => service.find(payload),
          (r) => ctx.commit(`set_${modelNamePluralize}`, r)
        );
      } else {
        return service
          .find(payload)
          .then((r) => ctx.commit(`set_${modelNamePluralize}`, r));
      }
    };
    this._vuexModule.actions[`create_${service._modelName}`] = (
      ctx,
      payload
    ) => {
      const result = service.create(payload);
      result.then((r) => {
        if (options.refresh_all) {
          return ctx.dispatch(`request_${modelNamePluralize}`);
        }
        ctx.commit(`set_${modelNamePluralize}`, [
          ...ctx.state[modelNamePluralize],
          r,
        ]);
      });
      return result;
    };
    this._vuexModule.actions[`update_${service._modelName}`] = (ctx, payload) =>
      service.update(payload).then((r) => {
        if (options.refresh_all) {
          return ctx.dispatch(`request_${modelNamePluralize}`);
        }
        const index = ctx.state[modelNamePluralize]
          .map((o) => o.id)
          .indexOf(r.id);
        if (index !== -1) ctx.state[modelNamePluralize][index] = r;
        ctx.commit(`set_${modelNamePluralize}`, [
          ...ctx.state[modelNamePluralize],
        ]);
      });
    this._vuexModule.actions[`delete_${service._modelName}`] = (ctx, payload) =>
      service
        .delete(payload)
        .then(() =>
          ctx.commit(`set_${modelNamePluralize}`, [
            ...ctx.state[modelNamePluralize].filter((x) => x.id !== payload.id),
          ])
        );
    // console.log(JSON.stringify(this._vuexModule));
  }

  _vuexModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  };

  get vuexModule() {
    return this._vuexModule;
  }
}

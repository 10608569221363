import { render, staticRenderFns } from "./AdminCompaniesView.vue?vue&type=template&id=572d6401&scoped=true"
import script from "./AdminCompaniesView.vue?vue&type=script&lang=js"
export * from "./AdminCompaniesView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572d6401",
  null
  
)

export default component.exports
<template>
  <v-card>
    <v-card-title class="justify-center text-h6 text-break text-center">{{
      title
    }}</v-card-title>
    <v-card-text class="px-0">
      <div class="player" v-if="!videoEnded">
        <n-s-player
          v-if="product"
          :video_data="video_data"
          :session_id="
            ['company-patient', 'session-by-url', 'patient-sessions'].includes(
              this.$route.name
            )
              ? `${this.item?.id}${this.patient_id ?? ''}`
              : undefined
          "
          :view_progress="item.viewProgress"
          @videoEnded="videoEndedHandler"
          @updateViewProgress="updateViewProgress"
        />
      </div>
      <div v-else class="text-center pl-4 mt-4">
        <h2>Спасибо за просмотр!</h2>
      </div>
      <v-card class="text-center d-flex flex-column justify-center" flat>
        <v-rating
          v-if="!rated"
          color="yellow darken-3"
          background-color="yellow darken-3"
          half-increments
          hover
          large
          class="ma-auto mt-4"
          @input="ratingChange($event)"
        >
        </v-rating>
        <div v-if="rated" class="mt-4">Спасибо за отзыв!</div>
        <div class="text-center text-sm-caption pl-4 mt-4" v-if="this.product">
          Рейтинг:
          {{
            this.product["rating_count"] != 0
              ? (
                  this.product["rating_sum"] / this.product["rating_count"]
                ).toFixed(1)
              : 0
          }}
          / Оценок: {{ this.product["rating_count"] }}
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NSPlayer from "@/components/ns-player/NSPlayer";

export default {
  name: "SessionForm",
  components: { NSPlayer },
  data() {
    return {
      rated: false,
      selected: [],
      videoEnded: false,
    };
  },
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    ...mapGetters("products", ["products"]),

    product() {
      return this.products.find((product) => {
        return product.kinescope_id === this.item.kinescope_id;
      });
    },
    video_data() {
      return this.product.kinescope_id;
    },
    title() {
      return this.modalFormComponent?.title;
    },
    desc() {
      return this.modalFormComponent?.desc;
    },
    item() {
      return {
        ...this.modalFormComponent?.item,
      };
    },
    patient_id() {
      return this.modalFormComponent?.patient_id;
    },
    viewProgress() {
      return this.item?.viewProgress;
    },
  },
  methods: {
    ...mapActions("products", ["update_product", "request_products"]),
    doPlaySession() {},
    ratingChange(value) {
      this.rated = true;
      this.product["rating_sum"] += value;
      this.product["rating_count"] += 1;

      this.update_product(this.product);
      this.request_products();
    },
    videoEndedHandler() {
      this.videoEnded = true;
      this.item.complete_reason = "Просмотрено";
      this.modalFormComponent?.archiveItem();
    },
    doSave(item) {
      const saveMethod = this.modalFormComponent?.saveMethod;
      if (saveMethod) {
        if (item.viewProgress === undefined)
          item.viewProgressFormatted = undefined;
        saveMethod(item).then(() => {
          this.modalFormComponent.item = { ...item };
        });
      }
    },

    formatTime(viewProgress) {
      if (viewProgress === undefined) return undefined;
      const roundedSeconds = Math.round(viewProgress);
      const minutes = Math.floor(roundedSeconds / 60);

      const remainingSeconds = roundedSeconds % 60;
      const formattedSeconds =
        remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      return `${minutes}:${formattedSeconds}`;
    },

    updateViewProgress(viewProgress) {
      this.item.viewProgress = viewProgress.toFixed(3);
      this.item.viewProgressFormatted = this.formatTime(viewProgress);
      this.doSave(this.item);
    },
  },
  beforeMount() {
    this.request_products();
  },
};
</script>

<style scoped lang="scss">
.player {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  margin: auto 10px;
}
</style>

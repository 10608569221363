<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Отчеты
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchToFirstReport" color="white">
        Отчет по сотрудникам, пациентам и списаниям
      </v-chip>
      <v-chip @click="switchToSecondReport" color="white">
        Финансовый отчет по компании за период
      </v-chip>
    </v-chip-group>
    <v-container fluid class="mx-0 px-0 my-0 py-0">
      <div
        class="pa-3 pa-sm-6"
        style="
          background-color: white;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        "
      >
        <v-row align="center">
          <v-col cols="3">
            <v-text-field
              v-model="searchCompany"
              label="Название компании"
              append-icon="mdi-magnify"
              class="mb-4"
              flat
              solo
              style="border: 1px solid #23919e; border-radius: 6px"
              single-line
              hide-details
              :readonly="isReadonly"
            ></v-text-field>
          </v-col>
          <v-col cols="3" v-if="showFirstReport">
            <v-text-field
              v-model="searchDoctor"
              label="Врач"
              append-icon="mdi-magnify"
              class="mb-4"
              flat
              solo
              style="border: 1px solid #23919e; border-radius: 6px"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3" v-if="showFirstReport">
            <v-text-field
              v-model="searchPatient"
              label="Пациент"
              append-icon="mdi-magnify"
              class="mb-4"
              flat
              solo
              style="border: 1px solid #23919e; border-radius: 6px"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRange"
                  label="Период"
                  append-outer-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:append-outer="menu = true"
                  class="mb-4"
                  flat
                  solo
                  style="
                    border: 1px solid #23919e;
                    border-radius: 6px;
                    padding-right: 12px;
                  "
                  single-line
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"
                  >Закрыть</v-btn
                >
                <v-btn text color="primary" @click="onDateSelected"
                  >Поиск</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-data-table
          :height="tableHeight"
          :headers="headers"
          :items="filteredItems"
          item-key="id"
          :mobile-breakpoint="0"
          class="table dense-table"
          disable-pagination
          hide-default-footer
          fixed-header
          dense
          multi-sort
        >
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/api/ApiService";
import { mapActions, mapGetters } from "vuex";
import momenttz from "moment-timezone";

export default {
  name: "ReportsView",
  data() {
    return {
      searchCompany: "",
      searchDoctor: "",
      searchPatient: "",
      showFirstReport: true,
      showSecondReport: false,
      selection: 0,
      menu: false,
      userTimezone: momenttz.tz.guess(),
      dates: [
        moment().subtract(30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      dateRange: "",
      headers1: [
        { text: "Сумма списания", value: "spent", width: "10%" },
        { text: "Название компании", value: "company_name", width: "10%" },
        { text: "Врач", value: "doctor", width: "10%" },
        { text: "Пациент", value: "patient", width: "10%" },
        { text: "Название продукта", value: "product_name", width: "8%" },
        { text: "Дата списания", value: "createdAt", width: "10%" },
      ],
      headers2: [
        { text: "Название компании", value: "company_name", width: "10%" },
        { text: "Приход", value: "income", width: "10%" },
        { text: "Расход", value: "spent", width: "10%" },
        {
          text: "Текущий баланс",
          value: "balance",
          width: "8%",
        },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("companies", ["companies"]),

    tableHeight() {
      if (!this.items.length) return "auto";

      // fixed-header doesn't work without an exact height value
      let tdHeight = 37;
      let thHeight = 37;
      let firstThElement = document.querySelector("th"); // Select the first <th> element
      if (firstThElement) {
        thHeight = firstThElement.offsetHeight;
      }

      let firstTdElement = document.querySelector("td"); // Select the first <td> element
      if (firstTdElement) {
        tdHeight = firstTdElement.offsetHeight; // Get the height of the <td> element
        if (tdHeight < 37) tdHeight = 37; // Minimum height should be 37
      }

      let calculatedHeight = tdHeight * this.filteredItems.length + thHeight;
      let maxTableHeight = this.$vuetify.breakpoint.height * 0.5;

      if (calculatedHeight > maxTableHeight) {
        return `${maxTableHeight}px`;
      }
      return `${calculatedHeight}px`;
    },
    isReadonly() {
      return this.user.role === "company-owner";
    },
    headers() {
      return this.showFirstReport ? this.headers1 : this.headers2;
    },
    filteredItems() {
      // Фильтрация для первого отчета
      const startDate = moment(this.dates[0]);
      const endDate = moment(this.dates[1]).endOf("day");

      let itemsWithFormattedDate = this.items.map((item) => ({
        ...item,
        formattedUpdatedAt: moment(item.updatedAt).format("DD MMMM YYYY HH:mm"),
      }));
      if (this.showFirstReport) {
        return itemsWithFormattedDate
          .filter((item) => {
            const isInDateRange = moment(item.createdAt).isBetween(
              startDate,
              endDate,
              null,
              "[]"
            );
            const matchesCompany =
              item.company_name
                ?.toLowerCase()
                .includes(this.searchCompany.toLowerCase()) || false;
            const matchesDoctor =
              item.doctor
                ?.toLowerCase()
                .includes(this.searchDoctor.toLowerCase()) || false;
            const matchesPatient =
              item.patient
                ?.toLowerCase()
                .includes(this.searchPatient.toLowerCase()) || false;
            return (
              isInDateRange &&
              matchesCompany &&
              matchesDoctor &&
              matchesPatient &&
              item.spent
            );
          })
          .map((item) => ({
            ...item,
            createdAt: this.formatDate(item.createdAt),
          }));
      } else {
        // Агрегирование данных для второго отчета
        return this.aggregatedItems.filter((item) => {
          const isInDateRange = moment(item.createdAt).isBetween(
            startDate,
            endDate,
            null,
            "[]"
          );
          const matchesCompany =
            item.company_name
              ?.toLowerCase()
              .includes(this.searchCompany.toLowerCase()) || false;
          return isInDateRange && matchesCompany;
        });
      }
    },
    aggregatedItems() {
      const aggregated = this.items.reduce((acc, item) => {
        if (!acc[item.company_name]) {
          acc[item.company_name] = {
            company_name: item.company_name,
            income: 0,
            spent: 0,
            balance: 0,
          };
        }
        acc[item.company_name].income += parseFloat(item.income) || 0;
        acc[item.company_name].spent += parseFloat(item.spent) || 0;
        return acc;
      }, {});

      // Обновляем баланс для каждого агрегированного элемента
      Object.values(aggregated).forEach((item) => {
        item.balance = this.companyBalance(item.company_name);
      });

      return Object.values(aggregated).filter(
        (item) => item.income !== 0 || item.spent !== 0
      );
    },
  },
  watch: {
    // Представителю компании доступен поиск только по его компании,
    // поэтому прибиваем ему компанию в поле поиска. Ps. С бэка ему возвращаются записи только по его компании.
    companies(newCompanies) {
      if (this.user.role === "company-owner" && newCompanies.length > 0) {
        const company = newCompanies.find(
          (company) => company.id === this.user.company_id
        );
        if (company) {
          this.searchCompany = company.name;
        }
      }
    },
  },
  methods: {
    ...mapActions("companies", ["request_companies"]),

    formatDate(date) {
      return moment(date).tz(this.userTimezone).format("YYYY-MM-DD HH:mm:ss");
    },
    companyBalance(companyName) {
      const company = this.companies.find(
        (company) => company.name === companyName
      );
      return company ? company.custom_data.balance : 0;
    },

    async get_reports() {
      const startDate = moment(this.dates[0]);
      const endDate = moment(this.dates[1]).endOf("day");
      const url = `/api/reports?startDate=${startDate}&endDate=${endDate}`;
      try {
        const reports = await ApiService.get(url);
        this.items = reports;
      } catch (error) {
        console.error("Failed to fetch reports:", error);
      }
    },
    switchToFirstReport() {
      this.showFirstReport = true;
      this.showSecondReport = false;
    },
    switchToSecondReport() {
      this.showFirstReport = false;
      this.showSecondReport = true;
    },
    onDateSelected() {
      this.menu = false; // Закрыть меню
      if (this.dates.length === 1) {
        this.dates = [
          moment(this.dates[0]).startOf("day").format("YYYY-MM-DD"),
          moment(this.dates[0]).endOf("day").format("YYYY-MM-DD"),
        ];
      } else {
        this.dates = this.sortDates(this.dates);
      }
      this.dateRange = this.dates.join(" - "); // Обновить dateRange
      this.get_reports(); // Вызвать get_reports после выбора дат
    },
    sortDates(dates) {
      // Создаем новый массив с отсортированными датами
      return dates.slice().sort((a, b) => new Date(a) - new Date(b));
    },
  },
  mounted() {
    this.request_companies();
    this.dateRange = this.dates.join(" - ");
  },
  beforeMount() {
    this.get_reports();
  },
};
</script>

<style scoped></style>

<template>
  <v-container fluid class="mx-0 px-0 my-0 py-0">
    <div
      class="pa-3 pa-sm-6"
      style="
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      "
    >
      <div class="mb-4">
        <v-text-field
          flat
          solo
          v-model="search"
          style="border: 1px solid #23919e; border-radius: 6px"
          append-icon="mdi-magnify"
          label="Найти"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        :height="tableHeight"
        :mobile-breakpoint="0"
        class="table"
        :items="value || []"
        :headers="headers"
        @input="itemSelected"
        dense
        multi-sort
        :show-select="selectable"
        :search="search"
        disable-pagination
        hide-default-footer
        fixed-header
        :loading="!value.length && loading"
        loading-text="Загрузка... Пожалуйста, подождите"
      >
        <template #item="{ item }">
          <tr>
            <td
              class="truncate"
              v-for="header in headers"
              :key="header.value"
              :style="{ 'text-align': header.align }"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <div v-if="header.value !== 'actions'" v-on="on">
                    {{ item[header.value] }}
                  </div>
                  <div v-else class="d-inline-flex">
                    <v-btn
                      icon
                      @click="$emit('doEditItem', item)"
                      color="primary"
                      v-if="showActions?.edit"
                    >
                      <v-icon small>mdi-playlist-edit</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="$emit('doDeleteItem', item)"
                      color="error"
                      v-if="showActions?.delete"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="$emit('doRestoreItem', item)"
                      color="success"
                      v-if="showActions?.restore"
                    >
                      <v-icon small>mdi-restore</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ item[header.value] }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { VTooltip } from "vuetify/lib";

export default {
  name: "ObjectGridAdvanced",
  components: { VTooltip },
  props: [
    "title",
    "value",
    "field-defs",
    "hide-toolbar",
    "show-actions",
    "selectable",
    "instanceId",
  ],
  emits: ["itemSelected", "doEditItem", "doDeleteItem", "doRestoreItem"],
  data() {
    return {
      search: localStorage.getItem(this.instanceId) || "",
      selected: undefined,
      loading: true,
    };
  },
  watch: {
    search(newVal) {
      localStorage.setItem(this.instanceId, newVal); // Update localStorage when search changes
    },
  },
  computed: {
    headers() {
      const modifiedHeaders = [...this.fieldDefs];

      // Add a header for the column with buttons
      modifiedHeaders.push({
        text: "Действия",
        value: "actions",
        width: "12%",
        sortable: false,
      });

      modifiedHeaders[0].align = "start"; // Align first column to the left

      for (let i = 1; i < modifiedHeaders.length; i++) {
        modifiedHeaders[i].align = "center"; // Align other columns to the center
      }

      return modifiedHeaders;
    },
    tableHeight() {
      if (!this.value.length) return "auto";

      // fixed-header doesn't work without an exact height value
      let tdHeight = 37;
      let thHeight = 37;
      let firstThElement = document.querySelector("th"); // Select the first <th> element
      if (firstThElement) {
        thHeight = firstThElement.offsetHeight;
      }

      let firstTdElement = document.querySelector("td"); // Select the first <td> element
      if (firstTdElement) {
        tdHeight = firstTdElement.offsetHeight; // Get the height of the <td> element
        if (tdHeight < 37) tdHeight = 37; // Minimum height should be 37
      }

      let calculatedHeight = tdHeight * this.value.length + thHeight;
      let maxTableHeight = this.$vuetify.breakpoint.height * 0.5;

      if (calculatedHeight > maxTableHeight) {
        return `${maxTableHeight}px`;
      }
      return `${calculatedHeight}px`;
    },
  },
  methods: {
    itemSelected(v) {
      this.selected = v;
      this.$emit("itemSelected", v);
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
table td {
  font-size: 12px;
}
</style>

<style lang="scss">
.table tbody tr:nth-child(even) {
  background-color: #f0f8f6;
}
table thead tr th {
  background-color: #23919e !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: normal;
}
table tbody tr td {
  font-size: 12px !important;
}

@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .truncate {
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

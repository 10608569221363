var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Пациенты ")]),_c('v-chip-group',{attrs:{"active-class":"primary accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Активные ")],1),_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-archive")]),_vm._v(" Архивные ")],1)],1),_c('object-grid-advanced',{attrs:{"title":"Пациенты","value":_vm.patients_with_responsible_info,"field-defs":[
      { text: 'Пациент', value: 'fio', width: '41%' },
      { text: 'Компания', value: 'company_name', width: '8%' },
      { text: 'Последний вход', value: 'last_entry', width: '8%' },
      { text: 'Ответственный', value: 'user_full_name', width: '31%' },
    ],"hide-toolbar":false,"show-actions":{
      edit: !_vm.selection,
    }},on:{"itemSelected":(v) => (this.selected = v),"doEditItem":(item) =>
        _vm.$router.push({
          name: 'manager-patient',
          params: {
            patient_id: item.id,
            show_company: item.company_name,
            no_editing: true,
          },
        })}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
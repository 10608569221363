<template>
  <div class="mb-10">
    <div class="my-4 d-flex justify-space-between text-h4">
      Новый пользователь
      <router-link
        :to="{ name: this.redirectBackTo }"
        class="d-flex align-center text--underline body-2 text-h6"
      >
        Все пользователи
      </router-link>
    </div>
    <div
      class="d-flex white pa-sm-11 shadow-2"
      style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
    >
      <user-form @confirm="addNewUser" />
    </div>
  </div>
</template>

<script>
import UserForm from "@/components/UserForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewUserView",
  components: { UserForm },
  computed: {
    ...mapGetters("companies", ["companies"]),
  },
  props: {
    redirectBackTo: String,
  },
  methods: {
    ...mapActions("users", ["request_users", "create_user"]),
    ...mapActions("companies", ["request_companies", "update_company"]),
    async addNewUser(payload) {
      const user = await this.create_user(payload);
      if (payload.role === "manager") {
        // Добавляем компанию, которая была отмечена для менеджера.
        await this.update_company({
          id: payload.manager_companies,
          manager_id: user.id,
        });
      }
      await this.request_users();
      this.$router.push({ name: this.redirectBackTo });
    },
  },
  beforeMount() {
    this.request_companies();
  },
};
</script>

<style scoped lang="scss"></style>

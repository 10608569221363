<template>
  <v-card color="#f0f8f6" class="overflow-hidden rounded-xl">
    <div class="text-right ma-3">
      <v-btn icon @click="$emit('cancel')">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-row justify="center" align="center" class="pb-5">
      <v-col cols="16" class="text-center text-h4">
        <div>Сброс пароля</div>
      </v-col>
    </v-row>
    <v-card-text>
      <v-card elevation="0" class="rounded-xl pa-5" id="tree">
        <v-text-field
          v-model="newPassword"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showNewPassword ? 'text' : 'password'"
          name="new-password"
          label="Новый пароль"
          counter
          @click:append="showNewPassword = !showNewPassword"
        ></v-text-field>
        <v-text-field
          v-model="repeatedPassword"
          :append-icon="showRepeatedPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showRepeatedPassword ? 'text' : 'password'"
          name="repeated-password"
          label="Повторите пароль"
          counter
          @click:append="showRepeatedPassword = !showRepeatedPassword"
        ></v-text-field>
        <v-card-text class="text-center pa-2">
          <div
            class="text-body-5 red--text"
            v-if="
              newPassword &&
              repeatedPassword &&
              newPassword !== repeatedPassword
            "
          >
            Пароли не совпадают
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text"
            style="
              background-color: #d86568;
              width: 150px;
              height: 50px;
              border-radius: 50px;
            "
            :disabled="!canSave"
            @click="savePassword"
          >
            Сбросить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      newPassword: "",
      repeatedPassword: "",
      showNewPassword: false,
      showRepeatedPassword: false,
      passwordRules: [
        (v) => !!v || "Обязательное поле",
        (v) =>
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/.test(v) ||
          "Минимум 8 символов, цифры, буквы, знаки",
        (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      ],
    };
  },
  methods: {
    savePassword() {
      this.$emit("confirm", this.newPassword);
    },
  },
  computed: {
    canSave() {
      return this.passwordRules.every((rule) => {
        return (
          this.newPassword === this.repeatedPassword &&
          rule(this.newPassword) === true &&
          rule(this.repeatedPassword) === true
        );
      });
    },
  },
};
</script>

<template>
  <v-container style="width: 600px; height: 550px">
    <v-card class="flex-with-image" elevation="4" light tag="section">
      <v-flex class="d-flex justify-end">
        <v-btn class="mt-3 no-underline" text large @click="$emit('goLogin')">
          <v-icon color="white" class="display-2" left>mdi-close</v-icon>
        </v-btn>
      </v-flex>
      <v-card-title>
        <v-flex class="d-flex justify-center align-center">
          <span class="white--text text-center text-h4 ma-10"
            >Восстановление пароля</span
          >
        </v-flex>
      </v-card-title>
      <v-flex class="flex-container">
        <div class="text-center ma-10">
          <div class="white--text text-h6">
            Для восстановления доступа обратитесь к администратору
            support@vizerapy.ru
          </div>
          <a class="white--text" href="mailto:support@vizerapy.ru"
            >Напишите нам</a
          >
        </div>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "RecoverForm",
};
</script>

<style scoped lang="scss">
.flex-with-image {
  border-radius: 30px !important;
  width: 600px;
  height: 550px;
  background-image: url("@/assets/login_page_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #23919e !important;
}
.text-field {
  height: 60px;
  width: 450px;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 20px !important;
  padding-top: 6px !important;
  padding-bottom: 10px !important;
  padding-left: 20px;
  padding-right: 20px;
}
.bottom-text {
  color: rgba(255, 255, 255, 0.5);
}
</style>

<style lang="scss">
#inspire {
  .v-application--wrap {
    justify-content: center;
  }
}
</style>

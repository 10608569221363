<template>
  <div>
    <v-card class="pa-2">
      <v-card-title class="text-break">{{
        modalFormComponent.title
      }}</v-card-title>
      <v-card-subtitle>{{ modalFormComponent.desc }}</v-card-subtitle>
      <v-card-actions>
        <v-btn color="primary" @click="$emit('confirm')">Да</v-btn>
        <v-btn color="error" @click="$emit('cancel')">Нет</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PromptForm",
  components: {},
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    value() {
      return this.modalFormComponent?.value;
    },
  },
  data() {
    return {};
  },
  methods: {
    doSave(value) {
      this.$emit("confirm", { ...value });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Категории продуктов
    </div>
    <v-card
      class="py-2 px-sm-16 py-sm-10"
      style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
    >
      <v-treeview :items="CATEGORIES">
        <template slot="label" slot-scope="{ item }">
          <div class="d-flex align-center">
            <span
              v-text="item.name"
              class="multiline-text"
              style="width: 75%"
            />
          </div>
        </template>
      </v-treeview>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CATEGORIES } from "@/utils/constants";
export default {
  name: "ManagerProductCategoriesView",
  components: {},
  data() {
    return {
      saving: false,
      upload_progress: 0,
      CATEGORIES,
    };
  },
  methods: {
    ...mapActions("modals", ["showModalForm"]),
    ...mapActions("products", ["create_product"]),
  },
};
</script>

<style scoped lang="scss">
.multiline-text {
  white-space: normal;
  word-wrap: break-word;
  width: 75%;
}
</style>

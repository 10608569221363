<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Пациенты
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      title="Пациенты"
      :value="patients_with_responsible_info"
      :field-defs="[
        { text: 'Пациент', value: 'fio', width: '45%' },
        { text: 'Ответственный', value: 'user_full_name', width: '43%' },
      ]"
      :hide-toolbar="false"
      :show-actions="{
        edit: !selection,
        delete: !selection,
        restore: selection,
      }"
      @itemSelected="(v) => (this.selected = v)"
      @doEditItem="
        (item) =>
          $router.push({
            name: 'admin-patient',
            params: {
              patient_id: String(item.id),
            },
          })
      "
      @doDeleteItem="deletePatient"
      @doRestoreItem="restorePatient"
      :instanceId="`patientsSearch_${selection ? 'archive' : 'active'}`"
    />
  </div>
</template>

<script>
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import PromptForm from "@/components/PromptForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AdminPatientsView",
  components: { ObjectGridAdvanced },
  data() {
    return {
      selected: [],
      selection: 0,
    };
  },
  computed: {
    ...mapGetters("patients", ["patients"]),
    ...mapGetters("users", ["users"]),

    title() {
      return this.modalFormComponent?.title;
    },
    desc() {
      return this.modalFormComponent?.desc;
    },
    patients_with_responsible_info() {
      return this.patients.map((patient) => {
        const user = this.users.find((user) => user.id === patient.user_id);
        const user_full_name = user && user.full_name ? user.full_name : "";
        return {
          ...patient,
          user_full_name: user_full_name,
        };
      });
    },
  },
  methods: {
    ...mapActions("patients", [
      "request_patients",
      "update_patient",
      "delete_patient",
    ]),
    ...mapActions("users", ["request_users", "delete_user", "update_user"]),
    ...mapActions("modals", ["showModalForm"]),
    deletePatient(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить пациента ${item.fio}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const account = this.users.find((user) => user.patient_id === item.id);
        if (account) this.delete_user(account);
        this.delete_patient(item).then(() => {
          this.request_patients();
        });
      });
    },
    restorePatient(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены, что хотите восстановить пациента ${item.fio}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const account = this.users.find((user) => user.patient_id === item.id);
        if (account) this.update_user({ ...account, active: true });
        const updatedPatient = { ...item, active: true };
        this.update_patient(updatedPatient).then(() => {
          this.request_patients("archive=0");
        });
      });
    },
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_patients(this.selection ? "archive=0" : "");
      });
    },
  },
  beforeMount() {
    this.request_patients();
    this.request_users("all=1");
  },
};
</script>

<style scoped></style>

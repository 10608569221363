var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Компании ")]),_c('v-chip-group',{attrs:{"active-class":"primary accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Активные ")],1),_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-archive")]),_vm._v(" Архивные ")],1)],1),_c('object-grid-advanced',{attrs:{"value":this.companies.map((company) => ({
        id: company.id,
        name: company.name,
        city: company.city,
        ...company.custom_data,
      })),"field-defs":[
      { text: 'Компания', value: 'name', width: '10%' },
      { text: 'ИНН', value: 'inn', width: '10%' },
      { text: 'ОГРН', value: 'ogrn', width: '10%' },
      { text: 'Пробный период', value: 'trial', width: '10%' },
      { text: 'Баланс', value: 'balance', width: '10%' },
      {
        text: 'Конец пробного периода',
        value: 'trial_end_time',
        width: '10%',
      },
      { text: 'E-mail', value: 'email', width: '10%' },
      { text: 'Телефон', value: 'phone', width: '10%' },
    ],"show-actions":{}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
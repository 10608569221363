<template>
  <v-app id="inspire">
    <login-form v-if="form === 'login'" @goRecover="form = 'recover'" />
    <recover-form v-if="form === 'recover'" @goLogin="form = 'login'" />
  </v-app>
</template>

<script>
import RecoverForm from "@/components/RecoverForm";
import LoginForm from "@/components/LoginForm";

export default {
  components: { LoginForm, RecoverForm },
  name: "AuthView",
  data() {
    return {
      form: "login",
    };
  },
};
</script>

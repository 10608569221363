const locales = {
  newModalFormResolver: null,
};

const modals = {
  namespaced: true,
  state: () => ({
    newModalFormVisible: false,
    modalFormComponent: undefined,
  }),
  mutations: {
    setNewModalFormVisible: (state, payload) =>
      (state.newModalFormVisible = payload),
    setModalFormComponent: (state, payload) =>
      (state.modalFormComponent = payload),
  },
  actions: {
    cancelNewModalForm: ({ commit }) => {
      locales.newModalFormResolver = null;
      commit("setNewModalFormVisible", false);
    },
    confirmNewModalForm: (ctx, payload) => {
      if (locales.newModalFormResolver) {
        locales.newModalFormResolver(payload);
      }
    },
    showModalForm: ({ commit, state }, payload) => {
      if (locales.newModalFormResolver || state.newModalFormVisible)
        return Promise.reject();
      return new Promise((resolve) => {
        locales.newModalFormResolver = (data) => {
          commit("setNewModalFormVisible", false);
          locales.newModalFormResolver = null;
          resolve(data);
        };
        commit("setModalFormComponent", payload);
        commit("setNewModalFormVisible", true);
      });
    },
    closeModalForm: ({ commit }) => commit("setNewModalFormVisible", false),
  },
  getters: {
    newModalFormVisible: (state) => state.newModalFormVisible,
    modalFormComponent: (state) => state.modalFormComponent,
  },
};

export default modals;

<template>
  <v-list-item
    :class="['justify-center', { 'active-item': active }]"
    style="border: 1px solid #f0f8f6"
    exact
    link
    :to="to"
  >
    <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <div style="width: 120px">
      <v-list-item-content>
        <v-list-item-title>
          <template v-if="label">{{ label }}</template>
        </v-list-item-title>
      </v-list-item-content>
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: "NsMenuItem",
  props: ["icon", "to", "label", "active"],
};
</script>

<style scoped lang="scss">
.v-list-item__title {
  font-size: 16px;
}
.active-item {
  color: white !important;
  background-color: #23919e;
  border: 1px solid #f0f8f6;
  .v-icon {
    color: white !important;
  }
}
.v-icon {
  color: #23919e !important;
}
</style>

<template>
  <div>
    <v-card-text class="my-5 d-flex justify-space-between align-center pa-4">
      <p class="text-h6">Ответственный: {{ responsible_user }}</p>
    </v-card-text>
    <v-card class="my-5">
      <v-card-text>
        <v-card-actions class="px-0">
          <div class="views-history text-body-1 text-sm-h5 black--text">
            История просмотров
          </div>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
            >
              4 сессии
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-card elevation="0" class="d-flex align-center">
            <v-card-text
              class="toolbar-content text-caption text-sm-subtitle-1 text-no-wrap py-1 px-2"
            >
              {{ (patient.visits || []).length }} визитов
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
        </v-card-actions>
        <object-grid
          :content_type="content_type"
          :value="patient.visits || []"
          :hide-toolbar="true"
          :show-actions="this.showActions"
          :field-defs="[
            { text: 'Сессия', value: 'product_name' },
            { text: 'Дата', value: 'date' },
          ]"
          @doEditItem="showSessionDialog"
        />
      </v-card-text>
    </v-card>
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>
<script>
import ObjectGrid from "@/components/ObjectGrid.vue";
import { mapActions, mapGetters } from "vuex";

import SessionForm from "@/components/SessionForm.vue";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

export default {
  name: "PatientView",
  components: { ObjectGrid, ErrorMessageWrapper },
  data() {
    return {
      content_type: "products",
      showActions: { edit: true },
      checkSessionInterval: undefined,
      openedSession: undefined,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("users", ["users"]),
    patient() {
      const item = this.user.Patient;
      return {
        ...(item || {}),
        age: item?.data?.age,
        comment: item?.data?.comment,
        problem: item?.data?.problem,
        condition: item?.data?.condition,
        review: item?.data?.review,
        visits: item?.data?.visits?.active ?? undefined,
      };
    },
    responsible_user() {
      const user = this.users.find(
        (user) => user.id === this.user.Patient.user_id
      );
      return user ? user.first_name + " " + user.last_name : "";
    },
    categorySessionsMap() {
      const resultMap = {};
      this.patient?.visits?.forEach((visit) => {
        // Обработка только visits у которых есть category_id и session_number
        // нужно для обратной совместимости с старыми сессиями (20.06.24)
        if (visit.category_id && visit.session_number) {
          const minimumSessionNumber = resultMap[visit.category_id] ?? Infinity;
          if (visit.session_number < minimumSessionNumber) {
            resultMap[visit.category_id] = visit.session_number;
          }
        }
      });

      return resultMap;
    },
  },
  methods: {
    ...mapActions("patients", ["request_patients", "update_patient"]),
    ...mapActions("users", ["request_users"]),
    ...mapActions("modals", ["showModalForm", "closeModalForm"]),
    ...mapActions(["doGetUser"]),

    loadPatient() {
      if (this.patient_id) this.request_patients(`id=${this.patient.id}`);
    },
    showSessionDialog(item) {
      // Проверка только visits у которых есть category_id и session_number
      // нужно для обратной совместимости с старыми сессиями (20.06.24)
      if (
        item.session_number &&
        item.session_number > this.categorySessionsMap[item.category_id]
      ) {
        this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          "Для просмотра данной сессии необходимо сначала ознакомиться с предыдущими. Ваша текущая сессия имеет номер " +
            this.categorySessionsMap[item.category_id]
        );
      } else {
        this.openedSession = item.id;

        this.showModalForm({
          title: item.product_name,
          component: SessionForm,
          width: this.$vuetify.breakpoint.smAndUp ? "50%" : "80%",
          item,
          patient_id: this.user.Patient.id,
          saveMethod: (item) => {
            return this.update_patient({
              id: this.patient.id,
              data: {
                ...this.patient.data,
                visits: {
                  ...this.patient?.data?.visits,
                  active: [
                    ...(this.patient?.data?.visits?.active || []).filter(
                      (v) => v.id !== item.id
                    ),
                    {
                      ...item,
                    },
                  ],
                  viewed: [...(this.patient?.data?.visits?.viewed || [])],
                },
              },
            }).then(() => this.loadPatient());
          },
          archiveItem: () => {
            item.complete_reason = "Просмотрено";
            this.archiveItem(item);
          },
        }).then((payload) => {
          console.log(payload);
        });
      }
    },
    archiveItem(item) {
      item.complete_date = `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
      this.update_patient({
        id: this.patient.id,
        data: {
          ...this.patient.data,
          visits: {
            active: [
              ...(this.patient?.data?.visits.active.filter(
                (v) => v.id !== item.id
              ) || []),
            ],
            viewed: [...(this.patient?.data?.visits.viewed || []), item],
          },
        },
      }).then(() => this.doGetUser());
    },
    async checkSession() {
      await this.doGetUser();

      const sessionExist = this.patient.visits.some(
        (session) => session.id === this.openedSession
      );

      if (!sessionExist) {
        this.closeModalForm();
      }
    },
  },
  beforeMount() {
    this.request_users();

    this.checkSessionInterval = setInterval(() => {
      this.checkSession();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.checkSessionInterval);
  },
};
</script>
<style scoped lang="scss">
.toolbar-content {
  background-color: #f0f8f6;
  color: #d86568 !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Продукты ")]),_c('object-grid-advanced',{attrs:{"hide-toolbar":true,"value":_vm.products.map((p) => ({
        ...p,
        category: p.Category?.name,
        product_type_name: p.product_type,
      })),"title":"Продукты","field-defs":[
      { text: 'Категория', value: 'category', width: '68%' },
      { text: 'Сессия', value: 'session_number', width: '10%' },
      { text: 'Тип', value: 'product_type_name', width: '10%' },
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  created() {
    // Retrieve user information
    const user = this.$store.getters.user;

    if (user && user.role) {
      switch (user.role) {
        case "admin":
          // Redirect to the company-users route for admin
          this.$router.push({ name: "admin-users" });
          break;
        case "company-user":
          // Redirect to the company-patients route for company users
          this.$router.push({ name: "company-patients" });
          break;
        case "company-owner":
          // Redirect to the company-patients route for company users
          this.$router.push({ name: "company-patients" });
          break;
        case "patient":
          this.$router.push({ name: "patient-sessions" });
          break;
        case "manager":
          this.$router.push({ name: "manager-users" });
          break;
        default:
          this.$router.push({ name: "home" });
          break;
      }
    }
  },
};
</script>

<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Продукты
    </div>
    <object-grid-advanced
      :hide-toolbar="true"
      :value="
        products.map((p) => ({
          ...p,
          category: p.Category?.name,
          product_type_name: p.product_type,
        }))
      "
      title="Продукты"
      :field-defs="[
        { text: 'Категория', value: 'category', width: '68%' },
        { text: 'Сессия', value: 'session_number', width: '10%' },
        { text: 'Тип', value: 'product_type_name', width: '10%' },
      ]"
    />
  </div>
</template>

<script>
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CompanyProductsView",
  components: { ObjectGridAdvanced },
  computed: {
    ...mapGetters("products", ["products"]),
  },
  mounted() {
    this.request_products();
  },
  methods: {
    ...mapActions("products", ["request_products"]),
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <object-grid
      :value="managers"
      title="Менеджеры"
      :field-defs="[
        { text: 'ФИО' },
        { text: 'E-Mail' },
        { text: 'Количество клиентов' },
      ]"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ObjectGrid from "@/components/ObjectGrid.vue";

export default {
  name: "AdminManagersView",
  components: { ObjectGrid },
  computed: {
    ...mapGetters("managers", ["managers"]),
  },
};
</script>

<style scoped lang="scss"></style>

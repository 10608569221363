<template>
  <v-card color="#f0f8f6" class="overflow-hidden rounded-xl">
    <div class="text-right ma-3">
      <v-btn icon @click="$emit('cancel')">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-row justify="center" align="center" class="pb-5">
      <v-col cols="16" class="text-center text-h4">
        <div>Назначить или отменить</div>
        <div>просмотр продукта</div>
      </v-col>
    </v-row>
    <v-card-text>
      <v-card elevation="0" class="rounded-xl" id="tree">
        <v-treeview :items="categories" @update:open="renderTreeNode">
          <template slot="label" slot-scope="{ item }">
            <div v-if="item.kinescope_id">
              <v-list>
                <v-list-group
                  v-for="p in [item].filter((i) => i.kinescope_id)"
                  :key="p.id"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-body-2"
                        style="max-width: 500px"
                        v-text="`Сессия №${p.session_number}`"
                      />
                    </v-list-item-content>
                  </template>
                  <v-list-item :key="p.id + item.kinescope_id">
                    <v-simple-checkbox
                      :value="selected_products.includes(p)"
                      @input="
                        selected_products.includes(p)
                          ? doUnselectSession(p)
                          : doSelectSession(p)
                      "
                      color="primary"
                    />
                    <v-list-item-title class="text-body-2">
                      {{ p.session_number }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </div>
            <div
              v-else
              :style="
                item.children.length === 0
                  ? { marginLeft: '30px' }
                  : { display: 'flex', gap: '10px' }
              "
            >
              {{ item.name }}
              <v-simple-checkbox
                v-if="
                  ['PC', 'VR', 'MG'].includes(item.name) &&
                  item.children.length !== 0
                "
                :value="
                  !item.children.some(
                    (product) => !selected_products.includes(product)
                  )
                "
                @input="
                  !item.children.some(
                    (product) => !selected_products.includes(product)
                  )
                    ? unselectAll(item.children)
                    : selectAll(item.children)
                "
              />
            </div>
          </template>
        </v-treeview>
      </v-card>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        class="white--text"
        style="
          background-color: #d86568;
          width: 150px;
          height: 50px;
          border-radius: 50px;
        "
        @click="$emit('confirm', [selected_products])"
        :disabled="selected_products.length === 0"
      >
        Назначить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { CATEGORIES } from "@/utils/constants";

export default {
  name: "SelectProductsForm",
  components: {},
  data() {
    return {
      selected_products: [],
      renderedNodes: [],
      // Копирование массива категорий
      categories: JSON.parse(JSON.stringify(CATEGORIES)),
    };
  },
  computed: {
    ...mapGetters("products", ["products"]),
    ...mapGetters("modals", ["modalFormComponent"]),
    title() {
      return this.modalFormComponent?.title;
    },
    desc() {
      return this.modalFormComponent?.desc;
    },
  },
  methods: {
    addProductsToCategory(category) {
      // Фильтрация по id категории и типу продукта
      // Сортировка по номеру сессии в порядке возрастания
      const PCProducts = this.products
        .filter(
          (product) =>
            product.category_id === category.id && product.product_type === "PC"
        )
        .sort((a, b) => a.session_number - b.session_number);
      const VRProducts = this.products
        .filter(
          (product) =>
            product.category_id === category.id && product.product_type === "VR"
        )
        .sort((a, b) => a.session_number - b.session_number);
      const MGProducts = this.products
        .filter(
          (product) =>
            product.category_id === category.id && product.product_type === "MG"
        )
        .sort((a, b) => a.session_number - b.session_number);

      // Добавление продуктов в подкатегории
      category.children = [
        {
          parentId: category.id,
          id: 100 + category.id,
          type: "PC",
          name: "PC",
          children: PCProducts,
        },
        {
          parentId: category.id,
          id: 200 + category.id,
          type: "VR",
          name: "VR",
          children: VRProducts,
        },
        {
          parentId: category.id,
          id: 300 + category.id,
          type: "MG",
          name: "MG",
          children: MGProducts,
        },
      ];
    },
    renderTreeNode(openedNodes) {
      // Рендер только впервые открытых категорий
      if (
        openedNodes.length > 0 &&
        !this.renderedNodes.includes(openedNodes[openedNodes.length - 1])
      ) {
        this.renderedNodes.push(openedNodes[openedNodes.length - 1]);
      } else {
        return;
      }

      // Для категорий PC, VR, MG рендер не нужен
      if (openedNodes[openedNodes.length - 1] >= 100) return;

      // Поиск открытой категории по её id
      function findItemById(categories, id) {
        for (const category of categories) {
          if (category.id === id) {
            return category;
          }
          if (category.children) {
            const found = findItemById(category.children, id);
            if (found) {
              return found;
            }
          }
        }
      }
      const openedItem = findItemById(
        this.categories,
        openedNodes[openedNodes.length - 1]
      );

      // Наполнение категорий PC, VR, MG продуктами
      if (openedItem.isEndNode) {
        this.addProductsToCategory(openedItem);
        return;
      }

      // Вставка {} в children конечных категорий
      // для того чтобы появилась возможность их открыть
      openedItem.children.forEach((child) => {
        if (child.children.length === 0) {
          child.children = [{}];
          child.isEndNode = true;
        }
      });
    },
    doSelectSession(p) {
      this.selected_products.push(p);
    },
    doUnselectSession(p) {
      this.selected_products = this.selected_products.filter(
        (item) => p !== item
      );
    },
    selectAll(toSelect) {
      toSelect = toSelect.filter(
        (item) => !this.selected_products.includes(item)
      );
      toSelect.forEach((p) => this.doSelectSession(p));
    },
    unselectAll(toUnselect) {
      toUnselect.forEach((p) => this.doUnselectSession(p));
    },
  },
};
</script>

<style scoped lang="scss">
.v-treeview {
  width: max-content;
}
@import "~vuetify/src/styles/styles.sass";

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-card #tree {
    overflow-x: scroll;
  }
}
</style>

<style lang="scss">
.v-list-group__header__append-icon {
  order: -1;
  margin-left: -25px !important;
  margin-right: 5px !important;
}
</style>

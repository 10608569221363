<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Компании
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      :value="
        this.companies.map((company) => ({
          id: company.id,
          name: company.name,
          city: company.city,
          ...company.custom_data,
        }))
      "
      :field-defs="[
        { text: 'Компания', value: 'name', width: '10%' },
        { text: 'ИНН', value: 'inn', width: '10%' },
        { text: 'ОГРН', value: 'ogrn', width: '10%' },
        { text: 'Пробный период', value: 'trial', width: '10%' },
        { text: 'Баланс', value: 'balance', width: '10%' },
        {
          text: 'Конец пробного периода',
          value: 'trial_end_time',
          width: '10%',
        },
        { text: 'E-mail', value: 'email', width: '10%' },
        { text: 'Телефон', value: 'phone', width: '10%' },
      ]"
      :show-actions="{}"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import moment from "moment";
import "moment/locale/ru";

export default {
  name: "ManagerCompaniesView",
  components: { ObjectGridAdvanced },
  computed: {
    ...mapGetters("companies", ["companies"]),
    ...mapGetters("users", ["users"]),
  },
  data: () => ({
    selection: 0,
  }),
  methods: {
    ...mapActions("companies", ["request_companies"]),
    ...mapActions("users", ["request_users"]),
    ...mapActions("modals", ["showModalForm"]),
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_companies(this.selection ? "archive=1" : "");
      });
    },
  },
  beforeMount() {
    this.request_companies();
    this.request_users();
  },
  mounted() {
    moment.locale("ru");
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div style="background-color: white">
    <property-form
      :value="
        value ||
        ['fio', 'user_id'].reduce((a, v) => {
          a[v] = '';
          return a;
        }, {})
      "
      :fields="fields"
      @input="doSave"
    />
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import PropertyForm from "@/components/PropertyForm";
import { mapActions, mapGetters } from "vuex";
import { VSelect } from "vuetify/lib/components";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";

export default {
  name: "NewPatientForm",
  components: { ErrorMessageWrapper, PropertyForm },
  data() {
    return {
      userIdValidationMessage: "",
      fioRules: [(v) => !!v || "Обязательное поле"],
      emailRules: [
        (v) => !v || /.+@.+\..+/.test(v) || "Пример: abc@de.fg",
        (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      ],
      passwordRules: [
        (v) =>
          !v ||
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/.test(v) ||
          "Минимум 8 символов, цифры, буквы, знаки",
        (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      ],
      userIdRules: [(v) => !!v || "Обязательное поле"],
    };
  },
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    ...mapGetters("users", ["users"]),

    value() {
      return this.modalFormComponent?.value;
    },
    fields() {
      return {
        fio: {
          order: 1,
          label: "ФИО",
          visible: true,
          rules: this.fioRules,
        },
        email: {
          order: 2,
          label: "E-Mail",
          visible: true,
          rules: this.emailRules,
        },
        user_id: {
          order: 3,
          label: "Ответственный",
          component: VSelect,
          items: this.users
            .filter((user) => user.role !== "patient")
            .map((c) => ({ value: c.id, text: c.full_name })),
          visible: true,
          rules: this.userIdRules,
        },
        password: {
          order: 4,
          label: "Пароль",
          visible: true,
          rules: this.passwordRules,
        },
      };
    },
  },
  beforeMount() {
    this.request_users();
  },
  methods: {
    ...mapActions("users", ["request_users"]),
    async doSave(value) {
      await this.request_users("all=1");
      // Existing user, password is undefined, skip password validation
      const conflictUser = this.users.find((user) => {
        return user.email === value.email;
      });

      if (conflictUser) {
        var message = "Попробуйте выбрать другой E-Mail";
        await this.$refs.error_wrapper.showErrorMessage(
          "Данный E-Mail уже занят",
          message
        );
        return;
      }
      this.$emit("confirm", { ...value });
    },
  },
};
</script>

<style scoped lang="scss">
.error-message {
  background-color: white;
  color: red;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 0px;
  padding-bottom: 5px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"},[_vm._v(" Продукты ")]),_c('v-chip-group',{attrs:{"active-class":"primary accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Активные ")],1),_c('v-chip',{attrs:{"color":"white"},on:{"click":_vm.switchActiveOrArchive}},[_c('v-icon',[_vm._v("mdi-archive")]),_vm._v(" Архивные ")],1)],1),_c('object-grid-advanced',{attrs:{"value":_vm.products.map((p) => ({
        ...p,
        category: p.Category?.name,
        product_type_name: p.product_type,
      })),"field-defs":[
      { text: 'Категория', value: 'category', width: '68%' },
      { text: 'Сессия', value: 'session_number', width: '10%' },
      { text: 'Тип', value: 'product_type_name', width: '10%' },
    ],"show-actions":{
      edit: !_vm.selection,
      delete: !_vm.selection,
      restore: _vm.selection,
    },"instanceId":`productsSearch_${_vm.selection ? 'archive' : 'active'}`},on:{"doEditItem":_vm.doEditProduct,"doDeleteItem":_vm.doDeleteProduct,"doRestoreItem":_vm.doRestoreProduct}}),(_vm.viewProductDialog)?_c('v-dialog',{attrs:{"value":_vm.selected_item,"content-class":"ma-2 ma-sm-6","max-width":_vm.$vuetify.breakpoint.smAndUp ? '50%' : '100%'},on:{"click:outside":_vm.closeNSPlayer}},[_c('v-card',{staticClass:"overflow-hidden pa-2 pa-sm-4"},[_c('v-card-title',{staticClass:"justify-center text-h6 text-sm-h5 text-break text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.concatItemTitle)),_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":function($event){_vm.changeCategoryDialog = true}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-playlist-edit")])],1)],1)]),_c('n-s-player',{ref:"nsPlayer",attrs:{"video_data":this.selected_item.kinescope_id}}),_c('v-dialog',{model:{value:(_vm.changeCategoryDialog),callback:function ($$v) {_vm.changeCategoryDialog=$$v},expression:"changeCategoryDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-break"},[_vm._v("Выберите новую категорию продукта")]),_c('v-treeview',{attrs:{"items":_vm.categories},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"multiline-text",staticStyle:{"width":"75%"},domProps:{"textContent":_vm._s(item.name)}}),_c('div',{staticClass:"ml-auto"},[(item.children.length === 0)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeProductCategory(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)])]}}],null,false,4088022723)})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";
import CRUDModel from "@/store/CRUDModel";
import BaseService from "@/api/services/BaseService";
import NSApi from "@/api/NSApi";
import modals from "@/store/modules/modals";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: undefined,
  },
  getters: {
    user: (state) => state.user,
  },
  mutations: {
    setUser: (state, payload) =>
      (state.user = payload
        ? {
            ...payload,
            role: payload?.role || "undefined",
          }
        : undefined),
    setProducts: (state, payload) => (state.products = payload),
    setManagers: (state, payload) => (state.managers = payload),
    setStopFlag: (state, value) => (state.stopFlag = value),
  },
  actions: {
    doLogout: ({ commit }) => {
      return NSApi.instance
        .doLogout()
        .then(() => commit("setUser", undefined))
        .then(() => router.push({ name: "home" }));
    },
    doLogin: ({ commit }, payload) => {
      return NSApi.instance
        .doLogin(payload.email, payload.password)
        .then((user) => commit("setUser", user));
    },
    doGetUser: ({ commit }) => {
      return NSApi.instance
        .getCurrentUser()
        .then((user) => commit("setUser", user));
    },
    doUpdateUser: ({ commit }, payload) => {
      return NSApi.instance
        .updateCurrentUser(payload)
        .then((user) => commit("setUser", user));
    },
  },
  modules: {
    modals,
    users: new CRUDModel(new BaseService("/api/users", "user")).vuexModule,
    products: new CRUDModel(new BaseService("/api/products", "product"))
      .vuexModule,
    companies: new CRUDModel(new BaseService("/api/companies", "company"))
      .vuexModule,
    managers: new CRUDModel(new BaseService("/api/managers", "manager"))
      .vuexModule,
    patients: new CRUDModel(new BaseService("/api/patients", "patient"))
      .vuexModule,
    categories: new CRUDModel(new BaseService("/api/categories", "category"))
      .vuexModule,
  },
});

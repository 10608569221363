import Axios from "axios";
import { SERVER_URI } from "@/api/NSApi";

export default class BaseService {
  get baseURI() {
    return SERVER_URI + this._baseURI;
  }
  find(query) {
    return this._axios
      .get(`${this.baseURI}.${this._format}/${this.queryParams(query)}`)
      .then((r) => r.data);
  }
  get(id) {
    return this._axios
      .get(`${this.baseURI}/${id}.${this._format}`)
      .then((r) => r.data);
  }
  create(object) {
    return this._axios
      .post(`${this.baseURI}.${this._format}`, {
        [this._modelName]: object,
      })
      .then((r) => r.data);
  }
  update(object) {
    return this._axios
      .put(`${this.baseURI}/${object.id}.${this._format}`, {
        [this._modelName]: object,
      })
      .then((r) => r.data);
  }
  delete(object) {
    return this._axios
      .delete(`${this.baseURI}/${object.id}.${this._format}`)
      .then((r) => r.data);
  }

  constructor(
    baseURI,
    modelName,
    format = "json",
    axios = Axios.create({ withCredentials: true })
  ) {
    this._axios = axios;
    this._baseURI = baseURI;
    this._format = format;
    this._modelName = modelName;
  }

  queryParams(query) {
    return query ? "?" + query : "";
  }
}

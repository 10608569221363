<template>
  <div class="px-2 px-sm-10">
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Сессии
    </div>
    <v-card class="pa-5">
      <object-grid
        :content_type="'products'"
        :value="visit || []"
        :show-actions="{
          edit: true,
        }"
        :field-defs="[
          { text: 'Сессия', value: 'product_name' },
          { text: 'Дата', value: 'date' },
        ]"
        @doEditItem="showSessionDialog"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CryptoJS from "crypto-js";
import SessionForm from "@/components/SessionForm";
import ObjectGrid from "@/components/ObjectGrid";

export default {
  name: "SessionByUrlView",
  components: { ObjectGrid },
  props: ["encrypted_patient_data"],

  data() {
    return {
      patient_id: undefined,
      session_id: undefined,
      checkSessionInterval: undefined,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("patients", ["patients"]),
    patient() {
      const item =
        this.patients && this.patients.find((i) => +i.id === +this.patient_id);
      return {
        ...(item || {}),
        age: item?.data?.age,
        comment: item?.data?.comment,
        problem: item?.data?.problem,
        condition: item?.data?.condition,
        review: item?.data?.review,
        visits: item?.data?.visits?.active,
      };
    },
    visit() {
      const visit =
        this.patient.visits &&
        this.patient.visits.find((i) => i.id === this.session_id);
      return visit ? [visit] : [];
    },
  },

  methods: {
    ...mapActions("patients", ["request_patients", "update_patient"]),
    ...mapActions("products", ["request_products"]),
    ...mapActions("modals", ["showModalForm", "closeModalForm"]),

    decryptPatientData() {
      const base64PatientDataDecoded = atob(this.encrypted_patient_data);

      const decryptedPatientData = CryptoJS.AES.decrypt(
        base64PatientDataDecoded,
        process.env.VUE_APP_CRYPT_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);

      const parsedPatientData = JSON.parse(decryptedPatientData);
      this.patient_id = parsedPatientData.patient_id;
      this.session_id = parsedPatientData.session_id;
    },

    loadPatient() {
      if (this.patient_id) this.request_patients(`id=${this.patient_id}`);
    },

    showSessionDialog(item) {
      this.showModalForm({
        title: item.product_name,
        component: SessionForm,
        width: this.$vuetify.breakpoint.smAndUp ? "50%" : "80%",
        item,
        patient_id: this.patient_id,
        saveMethod: (item) => {
          return this.update_patient({
            id: this.patient.id,
            data: {
              ...this.patient.data,
              visits: {
                ...this.patient?.data?.visits,
                active: [
                  ...(this.patient?.data?.visits?.active || []).filter(
                    (v) => v.id !== item.id
                  ),
                  {
                    ...item,
                  },
                ],
                viewed: [...(this.patient?.data?.visits?.viewed || [])],
              },
            },
          }).then(() => this.loadPatient());
        },
        archiveItem: () => {
          item.complete_reason = "Просмотрено";
          this.archiveItem(item);
        },
      }).then((payload) => {
        console.log(payload);
      });
    },

    archiveItem(item) {
      item.complete_date = `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
      this.update_patient({
        id: this.patient.id,
        data: {
          ...this.patient.data,
          visits: {
            active: [
              ...(this.patient?.data?.visits.active.filter(
                (v) => v.id !== item.id
              ) || []),
            ],
            viewed: [...(this.patient?.data?.visits.viewed || []), item],
          },
        },
      });
    },

    async checkSession() {
      await this.request_patients(`id=${this.patient_id}`);

      const sessionExist = this.patient.visits.some(
        (session) => session.id === this.session_id
      );

      if (!sessionExist) {
        this.closeModalForm();
      }
    },
  },
  beforeMount() {
    if (this.user.role == "patient") {
      this.decryptPatientData();
      this.request_products();
      this.request_patients(`id=${this.patient_id}`);
    }

    this.checkSessionInterval = setInterval(() => {
      this.checkSession();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.checkSessionInterval);
  },
};
</script>

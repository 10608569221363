<template>
  <v-card>
    <v-card-title class="text-break">{{
      modalFormComponent.title
    }}</v-card-title>
    <property-form :value="value" :fields="fields" @input="doSave">
    </property-form>
  </v-card>
</template>

<script>
import PropertyForm from "@/components/PropertyForm";
import { mapGetters } from "vuex";
export default {
  name: "ProductForm",
  components: { PropertyForm },
  data() {
    return {
      sessionPriceRules: [
        (v) => !!v || "Обязательное поле ",
        (v) => /^\d+$/.test(v) || "Стоимость должна быть числом",
      ],
    };
  },
  computed: {
    ...mapGetters("modals", ["modalFormComponent"]),
    value() {
      return { session_price: this.modalFormComponent?.session_price };
    },
    fields() {
      return {
        session_price: {
          order: 1,
          label: "Стоимость сессии",
          type: "number",
          visible: true,
          rules: this.sessionPriceRules,
        },
      };
    },
  },
  methods: {
    doSave(value) {
      this.$emit("confirm", { ...value });
    },
  },
};
</script>

<style scoped></style>

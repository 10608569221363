<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Пользователи
      <v-btn
        color="#d86568"
        large
        rounded
        class="pa-5 px-sm-10 white--text"
        @click="addNewUser"
      >
        <v-icon left>mdi-plus</v-icon>
        Добавить
      </v-btn>
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      :value="enhancedUsers"
      title="Пользователи"
      :field-defs="[
        { text: 'Компания', value: 'company_name', width: '12%' },
        { text: 'Пользователь', value: 'full_name', width: '23%' },
        { text: 'E-mail', value: 'email', width: '23%' },
        { text: 'Роль', value: 'user_type_text', width: '18%' },
        { text: 'Последний вход', value: 'last_entry', width: '12%' },
      ]"
      :show-actions="{
        edit: !selection,
        delete: !selection,
        restore: selection,
      }"
      @doEditItem="editUser"
      @doDeleteItem="deleteItem"
      @doRestoreItem="restoreItem"
      :instanceId="`usersSearch_${selection ? 'archive' : 'active'}`"
    />
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import UserForm from "@/components/UserForm.vue";
import PromptForm from "@/components/PromptForm.vue";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";
import { ROLE_TRANSLATIONS } from "@/utils/constants";

export default {
  name: "AdminUsersView",
  components: { ObjectGridAdvanced, ErrorMessageWrapper },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("patients", ["patients"]),
    ...mapGetters("companies", ["companies"]),
    ...mapGetters("modals", ["modalFormComponent"]),
    enhancedUsers() {
      return this.users
        .filter((user) => user.role !== "patient")
        .map((user) => {
          // Get full name
          const firstName = user.first_name || "";
          const lastName = user.last_name || "";
          const full_name =
            firstName && lastName ? `${firstName} ${lastName}` : "";
          // Get company name
          const company = this.companies.find((c) => c.id === user.company_id);
          const company_name = company ? company.name : "";
          // Get user type text
          let user_type_text = ROLE_TRANSLATIONS[user.role];
          const last_entry = user.last_entry
            ? new Date(user.last_entry).toLocaleString()
            : "";
          return {
            ...user,
            full_name,
            company_name,
            user_type_text,
            last_entry,
          };
        });
    },
  },
  data: () => ({
    selection: 0,
  }),
  methods: {
    ...mapActions("users", [
      "request_users",
      "create_user",
      "update_user",
      "delete_user",
    ]),
    ...mapActions("patients", ["request_patients"]),
    ...mapActions("modals", ["showModalForm"]),
    ...mapActions("companies", ["request_companies", "update_company"]),

    addNewUser() {
      // костыль, если перед этим было вызвано showModalForm с UserForm
      // то в this.modalFormComponent.value будет лежать value
      // с значениями ранее открытого пользователя
      // поэтому тут value обнуляется
      // TODO: исправить
      if (this.modalFormComponent) this.modalFormComponent.value = undefined;
      this.$router.push({ name: "admin-new-user" });
    },
    editUser(user) {
      this.showModalForm({
        component: UserForm,
        title: `Пользователь ${user.id}`,
        desc: ``,
        value: user,
      }).then((payload) => {
        this.update_user(payload).then(() => {
          this.request_users();
        });
        if (payload.role === "manager") {
          const manager_companies = this.companies.filter(
            (c) => c.manager_id === payload.id
          );
          // Удаляем компании, которые не были отмечены для менеджера.
          for (const index in manager_companies) {
            const company = manager_companies[index];
            if (payload.manager_companies.find((c) => c.value === company.id))
              continue;
            this.update_company({
              id: company.id,
              manager_id: null,
            });
          }
          // Добавляем компании, которые были отмечены для менеджера.
          for (const index in payload.manager_companies) {
            const company = payload.manager_companies[index];
            if (manager_companies.find((c) => c.id === company.value)) continue;
            this.update_company({
              id: company.value,
              manager_id: payload.id,
            });
          }
        }
      });
    },
    deleteItem(item) {
      if (item.id === this.user.id) {
        // Попытка удалить самого себя.
        this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          `Вы не можете удалить свой аккаунт.`
        );
        return;
      }
      const user_patients = this.patients.filter((patient) => {
        return patient.user_id === item.id;
      });
      if (user_patients.length != 0) {
        // За данным пользователем закреплены пациенты
        this.$refs.error_wrapper.showErrorMessage(
          "Ошибка",
          `За данным пользователем закреплены пациенты (${user_patients.length}).`
        );
        return;
      }
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить пользователя ${item.email}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        this.delete_user(item).then(() => {
          this.request_users();
        });
      });
    },
    restoreItem(item) {
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены, что хотите восстановить пользователя ${item.full_name}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const updatedUser = { ...item, active: true };
        this.update_user(updatedUser).then(() => {
          this.request_users("archive=1");
        });
      });
    },
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_users(this.selection ? "archive=1" : "");
        this.request_companies(this.selection ? "all=1" : "");
      });
    },
  },
  beforeMount() {
    this.request_patients();
    this.request_users();
    this.request_companies();
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <v-app app>
    <v-main style="background-color: #f0f8f6" class="mb-6">
      <v-container class="pa-0 ma-0" fluid app>
        <v-toolbar
          height="64x"
          flat
          style="z-index: 1"
          class="d-flex justify-end"
        >
          <v-toolbar-items>
            <v-btn
              depressed
              color="white"
              @click="doLogout"
              class="pa-0 flex-grow-1"
            >
              <div>
                <v-icon color="primary">mdi-exit-run</v-icon>
                Выход
              </div>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <slot />
      </v-container>
    </v-main>
    <v-footer></v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "PatientLayout",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["doLogout"]),
  },
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="error-message-title">
        {{ errorMessageTitle }}
      </v-card-title>
      <v-card-text class="error-message-text">
        {{ errorMessage }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          class="white--text"
          @click="dialog = false"
          style="
            background-color: #d86568;
            width: 150px;
            height: 50px;
            border-radius: 50px;
          "
          >OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorMessageWrapper",
  data() {
    return {
      errorMessage: "ErrorMessageText",
      dialog: false,
      errorMessageTitle: "ErrorMessageTitle",
    };
  },
  methods: {
    showErrorMessage(title, message) {
      this.errorMessageTitle = title;
      this.errorMessage = message;
      this.dialog = true;
    },
  },
};
</script>

<style>
.error-message-title {
  color: #d86568;
  font-weight: bold;
  justify-content: center;
}

.error-message-text {
  color: black;
  text-align: center;
}
</style>

<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Пользователи
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      :value="enhancedUsers"
      title="Пользователи"
      :field-defs="[
        { text: 'Компания', value: 'company_name', width: '12%' },
        { text: 'Пользователь', value: 'full_name', width: '23%' },
        { text: 'E-mail', value: 'email', width: '23%' },
        { text: 'Роль', value: 'user_type_text', width: '18%' },
        { text: 'Последний вход', value: 'last_entry', width: '12%' },
      ]"
      :show-actions="{}"
    />
    <error-message-wrapper ref="error_wrapper" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import ErrorMessageWrapper from "@/components/ErrorMessageWrapper.vue";
import { ROLE_TRANSLATIONS } from "@/utils/constants";

export default {
  name: "ManagerUsersView",
  components: { ObjectGridAdvanced, ErrorMessageWrapper },
  computed: {
    ...mapGetters("users", ["users"]),
    ...mapGetters("patients", ["patients"]),
    ...mapGetters("companies", ["companies"]),
    ...mapGetters("modals", ["modalFormComponent"]),

    filteredUsers() {
      return this.users.filter((user) => user.role !== "patient");
    },
    enhancedUsers() {
      return this.filteredUsers.map((user) => {
        // Get company name
        const company = this.companies.find((c) => c.id === user.company_id);
        const company_name = company ? company.name : "";
        // Get user type text
        let user_type_text = ROLE_TRANSLATIONS[user.role];
        const last_entry = user.last_entry
          ? new Date(user.last_entry).toLocaleString()
          : "";
        return {
          ...user,
          company_name,
          user_type_text,
          last_entry,
        };
      });
    },
  },
  data: () => ({
    selection: 0,
  }),
  methods: {
    ...mapActions("users", ["request_users"]),
    ...mapActions("patients", ["request_patients"]),
    ...mapActions("modals", ["showModalForm"]),
    ...mapActions("companies", ["request_companies"]),
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_users(this.selection ? "archive=1" : "");
        this.request_companies(this.selection ? "all=1" : "");
      });
    },
  },
  beforeMount() {
    this.request_patients();
    this.request_users();
    this.request_companies();
  },
};
</script>

<style scoped lang="scss"></style>

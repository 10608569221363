<template>
  <div>
    <div
      class="text-h6 text-sm-h4 my-4 d-flex justify-space-between align-center"
    >
      Продукты
    </div>
    <v-chip-group
      v-model="selection"
      active-class="primary accent-4 white--text"
      column
    >
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-check</v-icon> Активные
      </v-chip>
      <v-chip @click="switchActiveOrArchive" color="white">
        <v-icon>mdi-archive</v-icon> Архивные
      </v-chip>
    </v-chip-group>
    <object-grid-advanced
      :value="
        products.map((p) => ({
          ...p,
          category: p.Category?.name,
          product_type_name: p.product_type,
        }))
      "
      :field-defs="[
        { text: 'Категория', value: 'category', width: '68%' },
        { text: 'Сессия', value: 'session_number', width: '10%' },
        { text: 'Тип', value: 'product_type_name', width: '10%' },
      ]"
      :show-actions="{
        edit: !selection,
        delete: !selection,
        restore: selection,
      }"
      @doEditItem="doEditProduct"
      @doDeleteItem="doDeleteProduct"
      @doRestoreItem="doRestoreProduct"
      :instanceId="`productsSearch_${selection ? 'archive' : 'active'}`"
    />
    <v-dialog
      v-if="viewProductDialog"
      :value="selected_item"
      content-class="ma-2 ma-sm-6"
      :max-width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'"
      @click:outside="closeNSPlayer"
    >
      <v-card class="overflow-hidden pa-2 pa-sm-4">
        <v-card-title
          class="justify-center text-h6 text-sm-h5 text-break text-center"
        >
          <div>
            {{ concatItemTitle
            }}<v-btn large icon @click="changeCategoryDialog = true"
              ><v-icon color="primary">mdi-playlist-edit</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <n-s-player
          ref="nsPlayer"
          :video_data="this.selected_item.kinescope_id"
        />
        <v-dialog v-model="changeCategoryDialog"
          ><v-card>
            <v-card-title class="text-break"
              >Выберите новую категорию продукта</v-card-title
            >
            <v-treeview :items="categories">
              <template slot="label" slot-scope="{ item }">
                <div class="d-flex align-center">
                  <span
                    v-text="item.name"
                    class="multiline-text"
                    style="width: 75%"
                  />
                  <div class="ml-auto">
                    <v-btn
                      color="primary"
                      class="ml-4"
                      @click="changeProductCategory(item)"
                      v-if="item.children.length === 0"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-treeview></v-card
          ></v-dialog
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectGridAdvanced from "@/components/ObjectGridAdvanced.vue";
import NSPlayer from "@/components/ns-player/NSPlayer.vue";
import PromptForm from "@/components/PromptForm.vue";

import { CATEGORIES } from "@/utils/constants";

export default {
  name: "AdminProductsView",
  components: { NSPlayer, ObjectGridAdvanced },
  data() {
    return {
      selected_item: undefined,
      selected_session: undefined,
      selection: 0,
      viewProductDialog: false,
      changeCategoryDialog: false,
      // Копирование массива категорий
      categories: JSON.parse(JSON.stringify(CATEGORIES)),
    };
  },
  computed: {
    ...mapGetters("products", ["products"]),
    concatItemTitle() {
      return (
        this.selected_item.Category.name +
        " / Сессия " +
        this.selected_item.session_number +
        " / " +
        this.selected_item.product_type
      );
    },
  },
  mounted() {
    this.request_products();
    this.addEndSubcategories(this.categories);
  },
  updated() {
    if (this.$refs.nsPlayer) {
      this.$refs.nsPlayer.handlePause();
    }
  },
  methods: {
    ...mapActions("modals", ["showModalForm"]),
    ...mapActions("products", [
      "request_products",
      "create_product",
      "update_product",
      "delete_product",
    ]),
    doEditProduct(item) {
      this.selected_item = { ...item };

      this.viewProductDialog = true;
    },
    doDeleteProduct(item) {
      this.selected_item = { ...item };
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены что хотите удалить продукт ${this.concatItemTitle}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        this.delete_product(item).then(() => {
          this.request_products();
        });
      });
    },
    doRestoreProduct(item) {
      this.selected_item = { ...item };
      this.showModalForm({
        component: PromptForm,
        title: `Вы уверены, что хотите восстановить продукт ${this.concatItemTitle}?`,
        desc: `Подтвердите действие`,
        value: item,
      }).then(() => {
        const updatedProduct = { ...item, active: true };
        this.update_product(updatedProduct).then(() => {
          this.request_products("archive=1");
        });
      });
    },
    closeNSPlayer() {
      this.$refs.nsPlayer?.closeNSPlayer?.();
    },
    switchActiveOrArchive() {
      // check on next tick
      this.$nextTick(() => {
        this.request_products(this.selection ? "archive=1" : "");
      });
    },
    addEndSubcategories(categories) {
      categories.forEach((category) => {
        if (category.children && category.children.length === 0) {
          category.children = [
            {
              parentId: category.id,
              parentName: category.name,
              id: 100 + category.id,
              type: "PC",
              name: "PC",
              children: [],
            },
            {
              parentId: category.id,
              parentName: category.name,
              id: 200 + category.id,
              type: "VR",
              name: "VR",
              children: [],
            },
            {
              parentId: category.id,
              parentName: category.name,
              id: 300 + category.id,
              type: "MG",
              name: "MG",
              children: [],
            },
          ];
        } else if (category.children && category.children.length > 0) {
          this.addEndSubcategories(category.children);
        }
      });
    },
    changeProductCategory(category) {
      // Обновление названия продукта в открытом окне
      this.selected_item.Category.name = category.parentName;
      this.selected_item.product_type = category.type;

      // Обновление данных в БД
      this.selected_item.category_id = category.parentId;
      this.selected_item.product_type = category.type;
      this.update_product(this.selected_item).then(() => {
        this.request_products();
      });

      this.changeCategoryDialog = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>

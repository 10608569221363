<template>
  <div class="developer" v-if="accessGranted">
    <h1>This is a developer page</h1>
    <div>Requests</div>
    <v-btn @click="request_users()">Request Users</v-btn>
    <v-btn @click="request_products()">Request Products</v-btn>
    <v-btn @click="request_companies()">Request Companies</v-btn>
    <v-btn @click="request_patients()">Request Patients</v-btn>
    <div>Logs</div>
    <v-btn @click="log_users()">Log Users</v-btn>
    <v-btn @click="log_products()">Log Products</v-btn>
    <v-btn @click="log_companies()">Log Companies</v-btn>
    <v-btn @click="log_patients()">Log Patients</v-btn>
    <v-btn @click="log_env()">Log Env</v-btn>
    <div>Scripts</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeveloperView",
  data() {
    return {
      accessGranted: false,
    };
  },
  created() {
    this.checkAccess();
  },
  computed: {
    ...mapGetters("users", ["users"]),
    ...mapGetters("products", ["products"]),
    ...mapGetters("companies", ["companies"]),
    ...mapGetters("patients", ["patients"]),
  },
  methods: {
    ...mapActions("users", ["request_users"]),
    ...mapActions("products", ["request_products", "update_product"]),
    ...mapActions("companies", ["request_companies"]),
    ...mapActions("patients", ["request_patients"]),
    ...mapActions("modals", ["showModalForm"]),

    log_users() {
      console.log("User data:", this.users);
    },
    log_products() {
      console.log("Product data:", this.products);
    },
    log_companies() {
      console.log("Company data:", this.companies);
    },
    log_patients() {
      console.log("Patient data:", this.patients);
    },
    log_env() {
      console.log("Env:", process.env);
    },
    checkAccess() {
      const password = prompt("Please enter the password to access this page:");
      const correctPassword = "[!]";

      if (password === correctPassword) {
        this.accessGranted = true;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<template>
  <v-card color="#f0f8f6" class="overflow-hidden rounded-xl">
    <div class="text-right ma-3">
      <v-btn icon @click="$emit('cancel')">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-row justify="center" align="center" class="pb-5">
      <v-col cols="16" class="text-center text-h4">
        <div>Изменить почту</div>
      </v-col>
    </v-row>
    <v-card-text>
      <v-card elevation="0" class="rounded-xl pa-5" id="tree">
        <v-text-field
          v-model="newEmail"
          :append-icon="showNewEmail ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="emailRules"
          :type="showNewEmail ? 'text' : 'email'"
          name="new-email"
          label="Новый email"
          counter
          @click:append="showNewEmail = !showNewEmail"
        ></v-text-field>
        <v-text-field
          v-model="repeatedEmail"
          :append-icon="showRepeatedEmail ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="emailRules"
          :type="showRepeatedEmail ? 'text' : 'email'"
          name="repeated-email"
          label="Повторите email"
          counter
          @click:append="showRepeatedEmail = !showRepeatedEmail"
        ></v-text-field>
        <v-card-text class="text-center pa-2">
          <div
            class="text-body-5 red--text"
            v-if="newEmail && repeatedEmail && newEmail !== repeatedEmail"
          >
            Email не совпадают
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text"
            style="
              background-color: #d86568;
              width: 150px;
              height: 50px;
              border-radius: 50px;
            "
            :disabled="!canSave"
            @click="saveEmail"
          >
            Изменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EmailReset",
  data() {
    return {
      newEmail: "",
      repeatedEmail: "",
      showNewEmail: false,
      showRepeatedEmail: false,
      // emailRules: [
      //   (v) => !!v || "Обязательное поле",
      //   (v) =>
      //     /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/.test(v) ||
      //     "Минимум 8 символов, цифры, буквы, знаки",
      //   (v) => !v || !v.includes(" ") || "Пробелы запрещены",
      // ],
      emailRules: [
        (v) => (!!v && !v.includes(" ")) || "Обязательное поле без пробелов",
        (v) => /.+@.+\..+/.test(v) || "Пример: abc@de.fg",
      ],
    };
  },
  methods: {
    saveEmail() {
      this.$emit("confirm", this.newEmail);
    },
  },
  computed: {
    canSave() {
      return this.emailRules.every((rule) => {
        return (
          this.newEmail === this.repeatedEmail &&
          rule(this.newEmail) === true &&
          rule(this.repeatedEmail) === true
        );
      });
    },
  },
};
</script>
